import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

export class ContactUs extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <HeaderAfterLogin></HeaderAfterLogin>
        </div>
        <div className="container_contact">
          <h3>Contact Us</h3>
          <div className="contact-section">
            <a href="mailto:info@1stbet.asia">
              <img src="./images/query.svg" />
              <p>For queries.</p>
            </a>
            <a href="mailto:partners@1stbet.asia">
              <img src="./images/business.svg" />
              <p>Business with us.</p>
            </a>
            <a href="mailto:info@1stbet.asia">
              <img src="./images/business-partner.svg" />

              <p>Payment Partner </p>
            </a>
            <a href="mailto:partners@1stbet.asia">
              <img src="./images/marketting.svg" />
              <p>Affiliate Marketing.</p>
            </a>
          </div>
        </div>
        <div>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default ContactUs;
