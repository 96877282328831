import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import BackIcon from "../../../../assets/svg/BackIcon";
import BSCPay from "../../../../assets/images/bsc.png";
import { AiTwotoneCopy } from "react-icons/ai";
import BSCCode from "../../../../assets/images/bsc-qr-code.jpeg";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { useNavigate } from "react-router-dom";
import { createWithDrawRequest } from "../../../../redux/user/actions";
import { APP_CONST } from "../../../../config/const";
import { useSelector } from "react-redux";
import { Toaster } from "../../../../utils/toaster";
import { CopyToClipboard } from "react-copy-to-clipboard";
const BSCQRcodeWithdraw = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const validateData = (data) => {
    const dataToVerify = data ? data : formData;
    const { accountNo, amount } = dataToVerify;
    let errors = {};
    if (accountNo == "" || !accountNo) {
      errors.accountNo = "Please enter walletNo";
    }
    if (!amount || amount == 0) {
      errors.amount = "Please enter amount";
    }

    setError(errors);
    return errors;
  };
  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    let data = { ...formData, [name]: value };
    validateData(data);
    setFormData(data);
  };

  const handleSubmit = () => {
    if (Object.keys(validateData()).length === 0) {
      setLoading(true);
      createWithDrawRequest({
        ...formData,
        mode: APP_CONST.GATEWAYS.M_BINANCE_BSC,
        mobileNo: user?.mobileno,
      })
        .then((res) => {
          setLoading(false);
          if (res.data.error === false) {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Request Success");
          }
        })
        .catch((err) => {
          setLoading(false);
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    }
  };

  return (
    <div>
      <HeaderAfterLogin />
      <main className="main tron">
        <div className=" container">
          <div className="d-flex align-items-center justify-content-between">
            <h2 class="sectionTitle">
              <img src={BSCPay} /> Tether on BSC{" "}
            </h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="tron-qr-section">
            <div className="tron-form">
              {" "}
              <h2 class="sectionTitle">Request a Withdraw</h2>
              <div className="deposit-form mt-2">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      type="number"
                      placeholder="Amount*"
                      name="amount"
                      required
                      onChange={handleChange}
                      isInvalid={error?.amount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error?.amount}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Control
                      type="text"
                      placeholder=" Your wallet address*"
                      name="accountNo"
                      onChange={handleChange}
                      isInvalid={error?.accountNo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error?.accountNo}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
                <div className="payment-button">
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? "Updating.." : "Make Payment"}
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Return
                  </Button>
                </div>
              </div>{" "}
            </div>
            <div className="tron-qr-code d-none">
              <div className="qr-code-img text-center">
                <img src={BSCCode} />
              </div>
              <div className="tron-qr-content">
                {/* <p>USDT (Bep - 20) = 102.9847602 USD</p> */}
                <p className="copy-name">
                  Copy the address or scan the QR code:{" "}
                  <span>
                    <b>0xf1b144320e76e006a6aa74b08bb0507f4c9e950a</b>
                  </span>
                  <CopyToClipboard
                    text="0xf1b144320e76e006a6aa74b08bb0507f4c9e950a"
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text copied");
                    }}
                  >
                    <AiTwotoneCopy style={{ cursor: "pointer" }} />
                  </CopyToClipboard>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BSCQRcodeWithdraw;
