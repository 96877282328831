import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import BSCPay from "../../../../assets/images/bsc.png";
import { AiTwotoneCopy } from "react-icons/ai";
import BSCCode from "../../../../assets/images/bsc-bar-code.jpeg";
import { APP_CONST } from "../../../../config/const";
import { createDepositRequestHandler } from "../../../../redux/user/actions";
import { Toaster } from "../../../../utils/toaster";
import { useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

const BSCQRcode = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialForm = {
    name: "",
    walletNo: "",
    txnId: "",
  };

  const [formData, setFormData] = useState(initialForm);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    let data = { ...formData, [e.target.name]: e.target.value };
    validator(data);
    setFormData(data);
  };

  const validator = (payload) => {
    setFormErrors({});
    let errors = {};

    const dataToValidate = payload ? payload : formData;
    const { txnId, amount } = dataToValidate;
    if (!txnId || txnId == "") {
      errors.txnId = "TransactionId is required";
    }
    if (!amount || amount <= 0) {
      errors.amount = "Amount is required";
    }
    setFormErrors(errors);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = validator(formData);
    if (Object.keys(data).length === 0) {
      if (true) {
        setLoading(true);
        await createDepositRequestHandler({
          ...formData,
          gateway: APP_CONST.GATEWAYS.M_BINANCE_BSC,
        })
          .then((res) => {
            setLoading(false);

            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Request success");
            navigate("/cashier");
          })
          .catch((err) => {
            setLoading(false);
            Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
          });
        setFormData(initialForm);
        return;
      } else {
        alert("Amount must be greater than 300 and less than 25000");
      }
    }
  };
  return (
    <div>
      <div>
        <div>
          <HeaderAfterLogin />
          <main className="main tron">
            <div className=" container">
              <div className="d-flex align-items-center justify-content-between">
                <h2 class="sectionTitle">
                  <img src={BSCPay} /> Tether on BSC{" "}
                </h2>
                <div className="back-link" onClick={() => navigate(-1)}>
                  Back <BackIcon />
                </div>
              </div>

              <div className="tron-qr-section">
                <div className="tron-form">
                  <h2 class="sectionTitle">Request a Deposit</h2>
                  <div className="deposit-form mt-2">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Control
                          type="number"
                          placeholder="Amount*"
                          name="amount"
                          // value={""}
                          required
                          onChange={handleChange}
                          isInvalid={formErrors?.amount}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formErrors?.amount}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="number"
                          placeholder="Your Wallet Address*"
                          name="walletNo"
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="text"
                          placeholder="Your Name*"
                          name="name"
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="text"
                          placeholder="TRX ID"
                          name="txnId"
                          required
                          onChange={handleChange}
                          isInvalid={formErrors?.txnId}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formErrors?.txnId}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form>
                    <div className="payment-button">
                      <Button variant="primary" onClick={handleSubmit}>
                        {loading ? "Updating..." : "Make Payment"}
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Return
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="tron-qr-code">
                  <div className="qr-code-img text-center">
                    <img src={BSCCode} />
                  </div>
                  <div className="tron-qr-content">
                    {/* <p>USDT (Bep - 20) = 102.9847602 USD</p> */}
                    <p className="copy-name">
                      Copy the address or scan the QR code:{" "}
                      <span>
                        <b>0x5305163569441ceb87234ed51d52b2cffd2b8c03</b>
                      </span>
                      <CopyToClipboard
                        text="0x5305163569441ceb87234ed51d52b2cffd2b8c03"
                        onCopy={() => {
                          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                        }}
                      >
                        <AiTwotoneCopy />
                      </CopyToClipboard>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default BSCQRcode;
