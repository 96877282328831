import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

export class BettingRules extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <HeaderAfterLogin />
        </div>
        <main className="main page-content">
          <div className="container">
            <h3 className="page-heading">Betting Rules</h3>
            <div>
              <p>
                1.1. A bet can only be placed by a registered account holder.
              </p>
              <p>1.2. A bet can only be placed over the internet.</p>
              <p>
                1.3. You can only place a bet if you have sufficient funds in
                your account with 1stbet.
              </p>
              <p>
                1.4. The bet, once concluded, will be governed by the version of
                the Terms and Conditions valid and available on the Website at
                the time of the bet being accepted.
              </p>
              <p>
                1.5. Any payout of a winning bet is credited to your account,
                consisting of the stake multiplied by the odds at which the bet
                was placed.
              </p>
              <p>
                1.6. 1stbet reserves the right to adjust a bet payout credited
                to a 1stbet account if it is determined by 1stbet in its sole
                discretion that such a payout has been credited due to an error.
              </p>
              <p>
                1.7. A bet, which has been placed and accepted, cannot be
                amended, withdrawn or cancelled by you.
              </p>
              <p>
                1.8. The list of all the bets, their status and details are
                available to you on the Website.
              </p>
              <p>
                1.9. When you place a bet you acknowledge that you have read and
                understood in full all of these Terms and Conditions regarding
                the bet as stated on the Website.
              </p>
              <p>
                1.10. 1stbet manages your account, calculates the available
                funds, the pending funds, the betting funds as well as the
                amount of winnings. Unless proven otherwise, these amounts are
                considered as final and are deemed to be accurate.
              </p>
              <p>1.11. You are fully responsible for the bets placed.</p>
              <p>
                1.12. Winnings will be paid into your account after the final
                result is confirmed
              </p>
            </div>
          </div>
        </main>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default BettingRules;
