import { wazdan } from "./wazdan";

export const slotsData = {
  slotsPart1: {
    gonzoquest: {
      redirectUrl: "/casino/ezugi/gonzoquest",
      imgUrl: "./images/slots/Gonzos-Treasure-Hunt.jpg",
      name: "Gonzo Treasure Hunts",
    },
    slots2: {
      redirectUrl: "/casino/ezugi/asgardianstones",
      imgUrl: "./images/slots/asgardianstones.jpg",
      name: "Asgardian Stones",
    },
    slots3: {
      redirectUrl: "/casino/ezugi/secrets",
      imgUrl: "./images/slots/secrets.jpg",
      name: "Secrets of Atlantis",
    },
    slots4: {
      redirectUrl: "/casino/ezugi/grandwheel",
      imgUrl: "./images/slots/grand-wheel.jpg",
      name: "Grand Wheel",
    },
    slots5: {
      redirectUrl: "/casino/ezugi/Strike777",
      imgUrl: "./images/slots/777strike.jpg",
      name: "777 Strike",
    },
    slots6: {
      redirectUrl: "/casino/ezugi/Nights1000",
      imgUrl: "./images/slots/10001-nights.jpg",
      name: "10001 Nights",
    },
    slots7: {
      redirectUrl: "/casino/ezugi/CashUltimate",
      imgUrl: "./images/slots/cash_ultimate.jpg",
      name: "Cash Ultimate",
    },
    slots8: {
      redirectUrl: "/casino/ezugi/CashVolt",
      imgUrl: "./images/slots/cash_volt.jpg",
      name: "Cash Volt",
    },
    slots9: {
      redirectUrl: "/casino/ezugi/DazzleMegaways",
      imgUrl: "./images/slots/Dazzle-Memegaways.jpg",
      name: "Dazzle Me Megaways",
    },
    slots10: {
      redirectUrl: "/casino/ezugi/DivinieFortuneMegaways",
      imgUrl: "./images/slots/divine_fortune_megaways.jpg",
      name: "Divine Fortune Megaways",
    },
    slots11: {
      redirectUrl: "/casino/ezugi/Dynamiteriches",
      imgUrl: "./images/slots/dynamite_riches.jpg",
      name: "Dynamite Riches",
    },
    slots12: {
      redirectUrl: "/casino/ezugi/FortuneHouse",
      imgUrl: "./images/slots/fortunehouse.jpg",
      name: "Fotune House",
    },
    slots13: {
      redirectUrl: "/casino/ezugi/FruitShopMegaways",
      imgUrl: "./images/slots/Fruit_Shop_Megaways.jpg",
      name: "Fruit Shop Megaways",
    },
    slots14: {
      redirectUrl: "/casino/ezugi/GoldenCryptex",
      imgUrl: "./images/slots/Golden_Cryptex.jpg",
      name: "Golden Cryptex",
    },
    slots15: {
      redirectUrl: "/casino/ezugi/GoldenTsar",
      imgUrl: "./images/slots/Golden_Tsar.jpg",
      name: "Golden Tsar",
    },
    slots16: {
      redirectUrl: "/casino/ezugi/GorillaKingdom",
      imgUrl: "./images/slots/Gorilla_Kingdom.jpg",
      name: "Gorilla Kingdom",
    },
    slots17: {
      redirectUrl: "/casino/ezugi/MystryReels",
      imgUrl: "./images/slots/Mystery_Reels_Megaways.jpg",
      name: "Mystery Reels Megaways",
    },
    slots18: {
      redirectUrl: "/casino/ezugi/RageoftheSea",
      imgUrl: "./images/slots/Rage_Of_The_Seas.jpg",
      name: "Rage Of The Seas",
    },
    slots19: {
      redirectUrl: "/casino/ezugi/ReelKingMegaways",
      imgUrl: "./images/slots/Reel_King_Mega.jpg",
      name: "Reel King Mega",
    },
    slots20: {
      redirectUrl: "/casino/ezugi/TurnFortune",
      imgUrl: "./images/slots/Turn_Your_Fortune.jpg",
      name: "Turn Your Fortune",
    },
    slots21: {
      redirectUrl: "/casino/ezugi/TwinSpinMegaways",
      imgUrl: "./images/slots/Twin_Spin_Megaways.jpg",
      name: "Twin Spin Megaways",
    },
    slots22: {
      redirectUrl: "/casino/ezugi/WillysChills",
      imgUrl: "./images/slots/Willys_Hot_Chillies.jpg",
      name: "Willys Hot Chillies",
    },
    slots23: {
      redirectUrl: "/casino/ezugi/ChineseTreasures",
      imgUrl: "./images/slots/Chinese-Treasures.jpg",
      name: "Chinese Treasures",
    },
    slots24: {
      redirectUrl: "/casino/ezugi/DragonLuck",
      imgUrl: "./images/slots/Dragon's-Luck.jpg",
      name: "Dragon's Luck",
    },
    slots25: {
      redirectUrl: "/casino/ezugi/DragonFire",
      imgUrl: "./images/slots/Dragon's-Fire.jpg",
      name: "Dragon's Fire",
    },
    slots26: {
      redirectUrl: "/casino/ezugi/DragonLuckDeluxe",
      imgUrl: "./images/slots/Dragon's-Luck-Deluxe.jpg",
      name: "Dragon's Luck Deluxe",
    },
    slots27: {
      redirectUrl: "/casino/ezugi/DragonLuckReel",
      imgUrl: "./images/slots/Dragon's-Luck-Power-Reels.jpg",
      name: "Dragon's Luck Power Reels",
    },
    slots28: {
      redirectUrl: "/casino/ezugi/EpicJourney",
      imgUrl: "./images/slots/Epic-Journey.jpg",
      name: "Epic Journey",
    },
    slots29: {
      redirectUrl: "/casino/ezugi/GodWealth",
      imgUrl: "./images/slots/God-Of-Wealth.jpg",
      name: "God Of Wealth",
    },
    slots30: {
      redirectUrl: "/casino/ezugi/LuckyFortune",
      imgUrl: "./images/slots/Lucky-Fortune-Cat.jpg",
      name: "Lucky Fortune Cat",
    },
    slots31: {
      redirectUrl: "/casino/ezugi/MagicGate",
      imgUrl: "./images/slots/Magic-Gate.jpg",
      name: "Magic Gate",
    },
    slots32: {
      redirectUrl: "/casino/ezugi/GoldenTemple",
      imgUrl: "./images/slots/Golden-Temple.jpg",
      name: "Golden Temple",
    },

    slots33: {
      redirectUrl: "/casino/ezugi/PhoenixPower",
      imgUrl: "./images/slots/Phoenix-Fire-Power-Reels.jpg",
      name: "Phoenix Fire Power Reels",
    },
    slots34: {
      redirectUrl: "/casino/ezugi/GoldenOffer",
      imgUrl: "./images/slots/Golden-Offer.jpg",
      name: "Golden Offer",
    },
    slots35: {
      redirectUrl: "/casino/ezugi/RAsLegend",
      imgUrl: "./images/slots/RA's-Legend.jpg",
      name: "RA's Legend",
    },
    slots36: {
      redirectUrl: "/casino/ezugi/GonzoMegawaya",
      imgUrl: "./images/slots/Gonzo's-Quest-Megaways.jpg",
      name: "Gonzo's Quest Megaways",
    },
    slots37: {
      redirectUrl: "/casino/ezugi/RainbowJAcket",
      imgUrl: "./images/slots/Rainbow-Jackpots.jpg",
      name: "Rainbow Jackpots",
    },
    slots38: {
      redirectUrl: "/casino/ezugi/MegaPyramid",
      imgUrl: "./images/slots/Mega-Pyramid.jpg",
      name: "Mega Pyramid",
    },
    slots39: {
      redirectUrl: "/casino/ezugi/Jimi_Hendrix",
      imgUrl: "./images/slots/Jimi_Hendrix.jpg",
      name: "Jimi Hendrix",
    },
    slots40: {
      redirectUrl: "/casino/ezugi/Jumanji",
      imgUrl: "./images/slots/Jumanji.jpg",
      name: "Jumanji",
    },
    slots41: {
      redirectUrl: "/casino/ezugi/MotorheadVideoSlot",
      imgUrl: "./images/slots/Motorhead_Video_Slot.jpg",
      name: "Motorhead Video Slot",
    },
    slots42: {
      redirectUrl: "/casino/ezugi/Starburst",
      imgUrl: "./images/slots/Starburst.jpg",
      name: "Starburst",
    },
    slots43: {
      redirectUrl: "/casino/ezugi/WarofGod",
      imgUrl: "./images/slots/War-Of-Gods.jpg",
      name: "War Of Gods",
    },
    slots44: {
      redirectUrl: "/casino/ezugi/Christmas",
      imgUrl: "./images/slots/Aloha-Christmas.jpg",
      name: "Aloha Christmas",
    },
    slots45: {
      redirectUrl: "/casino/ezugi/ClusterPays",
      imgUrl: "./images/slots/Aloha-Cluster-Pays.jpg",
      name: "Aloha Cluster Pays",
    },
    slots46: {
      redirectUrl: "/casino/ezugi/AncientsBlessing",
      imgUrl: "./images/slots/Ancients-Blessing.jpg",
      name: "Ancients Blessing",
    },
    slots47: {
      redirectUrl: "/casino/ezugi/AurumCodex",
      imgUrl: "./images/slots/Aurum-Codex.jpg",
      name: "Aurum Codex",
    },
    slots48: {
      redirectUrl: "/casino/ezugi/GunsNRoses",
      imgUrl: "./images/slots/Guns_N_Roses.jpg",
      name: "Guns N Roses",
    },
    slots49: {
      redirectUrl: "/casino/ezugi/BorisandBoo",
      imgUrl: "./images/slots/Betty-Boris-and-Boo.jpg",
      name: "Betty Boris and Boo",
    },

    slots50: {
      redirectUrl: "/casino/ezugi/WildWest",
      imgUrl: "./images/slots/Wild-Wild-West-The-Great-Train-Heist.jpg",
      name: "Wild West The Great Train Heist",
    },
    slots51: {
      redirectUrl: "/casino/ezugi/SteamTower",
      imgUrl: "./images/slots/Steam-Tower.jpg",
      name: "Steam Tower",
    },
    slots52: {
      redirectUrl: "/casino/ezugi/ZeusLightning",
      imgUrl: "./images/slots/Zeus-Lightning-Power-Reels.jpg",
      name: "Zeus Lightning Power Reels",
    },
    slots53: {
      redirectUrl: "/casino/ezugi/WingsofRa",
      imgUrl: "./images/slots/Wings-of-Ra.jpg",
      name: "Wings of Ra",
    },
    slots54: {
      redirectUrl: "/casino/ezugi/Trillionaire",
      imgUrl: "./images/slots/Trillionaire.jpg",
      name: "Trillionaire",
    },
    slots55: {
      redirectUrl: "/casino/ezugi/TreasureMine",
      imgUrl: "./images/slots/Treasure-Mine.jpg",
      name: "Treasure Mine",
    },
    slots56: {
      redirectUrl: "/casino/ezugi/ThorVengeance",
      imgUrl: "./images/slots/Thor's-Vengeance.jpg",
      name: "Thor's Vengeance",
    },
    slots57: {
      redirectUrl: "/casino/ezugi/RegalStreak",
      imgUrl: "./images/slots/Regal-Streak.jpg",
      name: "Regal Streak",
    },
    slots58: {
      redirectUrl: "/casino/ezugi/RegalBeasts",
      imgUrl: "./images/slots/Regal-Beasts.jpg",
      name: "Regal Beasts",
    },
    slots59: {
      redirectUrl: "/casino/ezugi/ReelKeeper",
      imgUrl: "./images/slots/Reel-Keeper.jpg",
      name: "Reel Keeper",
    },
    slots60: {
      redirectUrl: "/casino/ezugi/RainbowJackpotsPowerLines",
      imgUrl: "./images/slots/Rainbow-Jackpots-Power-Lines.jpg",
      name: "Rainbow Jackpots Power Lines",
    },
    slots61: {
      redirectUrl: "/casino/ezugi/PiggyRichesMegaways",
      imgUrl: "./images/slots/Piggy-Riches-Megaways.jpg",
      name: "Piggy Riches Megaways",
    },
    slots62: {
      redirectUrl: "/casino/ezugi/JingleBells",
      imgUrl: "./images/slots/Jingle-Bells.jpg",
      name: "Jingle Bells",
    },
    slots63: {
      redirectUrl: "/casino/ezugi/JackinaPot",
      imgUrl: "./images/slots/Jack-in-a-Pot.jpg",
      name: "Jack in a Pot",
    },
    slots64: {
      redirectUrl: "/casino/ezugi/FiveStar",
      imgUrl: "./images/slots/Five-Star.jpg",
      name: "Five Star",
    },
    slots65: {
      redirectUrl: "/casino/ezugi/BountyRaid",
      imgUrl: "./images/slots/Bounty-Raid.jpg",
      name: "Bounty Raid",
    },
    slots66: {
      redirectUrl: "/casino/ezugi/MysteryReels2",
      imgUrl: "./images/slots/Mystery-Reels.jpg",
      name: "Mystery Reels",
    },

    slots67: {
      redirectUrl: "/casino/ezugi/DiamondBlitz",
      imgUrl: "./images/slots/Diamond-Blitz.jpg",
      name: "Diamond Blitz",
    },
    slots68: {
      redirectUrl: "/casino/ezugi/DragonFireMegaways",
      imgUrl: "./images/slots/Dragon's-Fire-Megaways.jpg",
      name: "Dragon's Fire Megaways",
    },
    slots69: {
      redirectUrl: "/casino/ezugi/Rome",
      imgUrl: "./images/slots/Rome-The-Golden-Age.jpg",
      name: "Rome-The Golden Age",
    },
    slots70: {
      redirectUrl: "/casino/ezugi/ReelRush",
      imgUrl: "./images/slots/Reel-Rush.jpg",
      name: "Reel Rush",
    },
    slots71: {
      redirectUrl: "/casino/ezugi/BloodSuckers",
      imgUrl: "./images/slots/Blood-Suckers.jpg",
      name: "Blood Suckers",
    },
    slots72: {
      redirectUrl: "/casino/ezugi/BloodSuckers2",
      imgUrl: "./images/slots/Blood-Suckers-2.jpg",
      name: "Blood Suckers 2",
    },
    slots73: {
      redirectUrl: "/casino/ezugi/Pyramid",
      imgUrl: "./images/slots/Pyramid-Quest-for-Immortality.jpg",
      name: "Pyramid Quest for Immortality",
    },
    slots74: {
      redirectUrl: "/casino/ezugi/ButterflyStaxx",
      imgUrl: "./images/slots/Butterfly-Staxx.jpg",
      name: "Butterfly Staxx",
    },
    slots75: {
      redirectUrl: "/casino/ezugi/ButterflyStaxx2",
      imgUrl: "./images/slots/Butterfly-Staxx-2.jpg",
      name: "Butterfly Staxx 2",
    },
    slots76: {
      redirectUrl: "/casino/ezugi/PiggyRiches",
      imgUrl: "./images/slots/Piggy-Riches.jpg",
      name: "Piggy Riches",
    },
    slots77: {
      redirectUrl: "/casino/ezugi/DarkKing",
      imgUrl: "./images/slots/Dark-King-Forbidden-Riches.jpg",
      name: "Dark King Forbidden Riches",
    },
    slots78: {
      redirectUrl: "/casino/ezugi/JingleBellsPowerReels",
      imgUrl: "./images/slots/Jingle-Bells-Power-Reels.jpg",
      name: "Jingle Bells Power Reels",
    },
    slots79: {
      redirectUrl: "/casino/ezugi/Dazzle",
      imgUrl: "./images/slots/Dazzle-Me.jpg",
      name: "Dazzle Me",
    },
    slots80: {
      redirectUrl: "/casino/ezugi/JackHammer",
      imgUrl: "./images/slots/Jack-Hammer.jpg",
      name: "Jack Hammer",
    },
    slots81: {
      redirectUrl: "/casino/ezugi/JackHammer2",
      imgUrl: "./images/slots/Jack-Hammer-2-Fishy-Business.jpg",
      name: "Jack Hammer 2 Fishy Business",
    },
    slots82: {
      redirectUrl: "/casino/ezugi/DeadorAlive",
      imgUrl: "./images/slots/Dead-or-Alive.jpg",
      name: "Dead or Alive",
    },
    slots83: {
      redirectUrl: "/casino/ezugi/DeadorAlive2",
      imgUrl: "./images/slots/Dead-or-Alive-2.jpg",
      name: "Dead or Alive 2",
    },

    slots84: {
      redirectUrl: "/casino/ezugi/DeadorAlive2FeatureBuy",
      imgUrl: "./images/slots/Dead-or-Alive-2-Feature-Buy.jpg",
      name: "Dead or Alive 2 Feature Buy",
    },
    slots85: {
      redirectUrl: "/casino/ezugi/JackandtheBeanstalk",
      imgUrl: "./images/slots/Jack-and-the-Beanstalk.jpg",
      name: "Jack and the Beanstalk",
    },
    slots86: {
      redirectUrl: "/casino/ezugi/JingleSpin",
      imgUrl: "./images/slots/Jingle-Spin.jpg",
      name: "Jingle Spin",
    },
    slots87: {
      redirectUrl: "/casino/ezugi/GordonRamsayHellsKitchen",
      imgUrl: "./images/slots/Gordon_Ramsay_Hells_Kitchen.jpg",
      name: "Gordon Ramsay Hells Kitchen",
    },
    slots88: {
      redirectUrl: "/casino/ezugi/Elements",
      imgUrl: "./images/slots/Elements-The-Awakening.jpg",
      name: "Elements The Awakening",
    },
    slots89: {
      redirectUrl: "/casino/ezugi/GonzosQuest",
      imgUrl: "./images/slots/Gonzos_Quest.jpg",
      name: "Gonzos Quest",
    },
    slots90: {
      redirectUrl: "/casino/ezugi/FinnandtheSwirlySpin",
      imgUrl: "./images/slots/Finn-and-the-Swirly-Spin.jpg",
      name: "Finn and the Swirly Spin",
    },
    slots91: {
      redirectUrl: "/casino/ezugi/FruitShop",
      imgUrl: "./images/slots/Fruit-Shop.jpg",
      name: "Fruit Shop",
    },
    slots92: {
      redirectUrl: "/casino/ezugi/DazzleMegaways",
      imgUrl: "./images/slots/Dazzle-Memegaways.jpg",
      name: "Dazzle Me",
    },
    slots93: {
      redirectUrl: "/casino/ezugi/DoubleStacks",
      imgUrl: "./images/slots/Double-Stacks.jpg",
      name: "Double Stacks",
    },
    slots94: {
      redirectUrl: "/casino/ezugi/FairytaleLegends",
      imgUrl: "./images/slots/Fairytale-Legends-Red-Riding-Hood.jpg",
      name: "Fairytale Legends Red Riding Hood",
    },
    slots95: {
      redirectUrl: "/casino/ezugi/Flowers",
      imgUrl: "./images/slots/Flowers.jpg",
      name: "Flowers",
    },
    slots96: {
      redirectUrl: "/casino/ezugi/FruitShopChristmasEdition",
      imgUrl: "./images/slots/Fruit-Shop-Christmas-Edition.jpg",
      name: "Fruit Shop Christmas Edition",
    },
    slots97: {
      redirectUrl: "/casino/ezugi/Hotline",
      imgUrl: "./images/slots/Hotline.jpg",
      name: "Hotline",
    },
    slots98: {
      redirectUrl: "/casino/ezugi/Hotline2",
      imgUrl: "./images/slots/Hotline-2.jpg",
      name: "Hotline 2",
    },
    slots99: {
      redirectUrl: "/casino/ezugi/TheInvisibleMan",
      imgUrl: "./images/slots/The_Invisible_Man.jpg",
      name: "The Invisible Man",
    },
    slots100: {
      redirectUrl: "/casino/ezugi/IrishPotLuck",
      imgUrl: "./images/slots/Irish-Pot-Luck.jpg",
      name: "Irish Pot Luck",
    },

    slots101: {
      redirectUrl: "/casino/ezugi/FruitSpin",
      imgUrl: "./images/slots/Fruit-Spin.jpg",
      name: "Fruit Spin",
    },
    slots102: {
      redirectUrl: "/casino/ezugi/JungleSpirit",
      imgUrl: "./images/slots/Jungle-Spirit-Call-of-the-Wild.jpg",
      name: "Jungle Spirit Call of the Wild",
    },
    slots103: {
      redirectUrl: "/casino/ezugi/KoiPrincess",
      imgUrl: "./images/slots/Koi-Princess.jpg",
      name: "Koi Princess",
    },
    slots104: {
      redirectUrl: "/casino/ezugi/DriveMultiplierMayhem",
      imgUrl: "./images/slots/Drive-Multiplier-Mayhem.jpg",
      name: "Drive Multiplier Mayhem",
    },
    slots105: {
      redirectUrl: "/casino/ezugi/RageoftheSeas",
      imgUrl: "./images/slots/Rage-of-the-Seas.jpg",
      name: "Rage of the Seas",
    },
    slots106: {
      redirectUrl: "/casino/ezugi/RichesofMidgard",
      imgUrl: "./images/slots/Riches-of-Midgard-Land-and-Expand.jpg",
      name: "Riches of Midgard Land and Expand",
    },
    slots107: {
      redirectUrl: "/casino/ezugi/ScruffyDuck",
      imgUrl: "./images/slots/Scruffy-Duck.jpg",
      name: "Scruffy Duck",
    },
    slots108: {
      redirectUrl: "/casino/ezugi/SecretoftheStones",
      imgUrl: "./images/slots/Secret-of-the-Stones.jpg",
      name: "Secret of the Stones",
    },
    slots109: {
      redirectUrl: "/casino/ezugi/SecretsofChristmas",
      imgUrl: "./images/slots/Secrets-of-Christmas.jpg",
      name: "Secret of Christmas",
    },
    slots110: {
      redirectUrl: "/casino/ezugi/SerengetiKings",
      imgUrl: "./images/slots/Serengeti-Kings.jpg",
      name: "Serengeti Kings",
    },
    slots111: {
      redirectUrl: "/casino/ezugi/SpaceWars",
      imgUrl: "./images/slots/Space-Wars.jpg",
      name: "Space Wars",
    },
    slots112: {
      redirectUrl: "/casino/ezugi/Victorious",
      imgUrl: "./images/slots/Victorious.jpg",
      name: "Victorious",
    },
    slots113: {
      redirectUrl: "/casino/ezugi/Warlords",
      imgUrl: "./images/slots/Warlords-Crystal-of-Power.jpg",
      name: "Warlords Crystal of Power",
    },
    slots114: {
      redirectUrl: "/casino/ezugi/WildWater",
      imgUrl: "./images/slots/Wild-Water.jpg",
      name: "Wild Water",
    },
    slots115: {
      redirectUrl: "/casino/ezugi/WildWorlds",
      imgUrl: "./images/slots/Wild-Worlds.jpg",
      name: "Wild Worlds",
    },
    slots116: {
      redirectUrl: "/casino/ezugi/Squad4",
      imgUrl: "./images/slots/4-Squad.jpg",
      name: "4 Squad",
    },
    slots117: {
      redirectUrl: "/casino/ezugi/Families5",
      imgUrl: "./images/slots/5-Families.jpg",
      name: "5 Families",
    },

    slots118: {
      redirectUrl: "/casino/ezugi/AgentRoyale",
      imgUrl: "./images/slots/Agent-Royale.jpg",
      name: "Agent Royale",
    },
    slots118: {
      redirectUrl: "/casino/ezugi/ArcadeBomb",
      imgUrl: "./images/slots/Arcade-Bomb.jpg",
      name: "Arcade Bomb",
    },
    slots119: {
      redirectUrl: "/casino/ezugi/Atlantis",
      imgUrl: "./images/slots/Atlantis.jpg",
      name: "Atlantis",
    },
    slots120: {
      redirectUrl: "/casino/ezugi/AztecSpins",
      imgUrl: "./images/slots/Aztec-Spins.jpg",
      name: "Aztec Spins",
    },
    slots121: {
      redirectUrl: "/casino/ezugi/CinderellaBall",
      imgUrl: "./images/slots/Cinderella`s-Ball.jpg",
      name: "Cinderella`s Ball",
    },
    slots122: {
      redirectUrl: "/casino/ezugi/CirquedelaFortune",
      imgUrl: "./images/slots/Cirque-de-la-Fortune.jpg",
      name: "Cirque de la Fortune",
    },
    slots123: {
      redirectUrl: "/casino/ezugi/ClashoftheBeasts",
      imgUrl: "./images/slots/Clash-of-the-Beasts.jpg",
      name: "Clash of the Beasts",
    },
    slots124: {
      redirectUrl: "/casino/ezugi/CrazyGenie",
      imgUrl: "./images/slots/Crazy-Genie.jpg",
      name: "Crazy Genie",
    },
    slots125: {
      redirectUrl: "/casino/ezugi/CrystalMirror",
      imgUrl: "./images/slots/Crystal-Mirror.jpg",
      name: "Crystal Mirror",
    },
    slots126: {
      redirectUrl: "/casino/ezugi/DaVinciMystery",
      imgUrl: "./images/slots/Da-Vinci`s-Mystery.jpg",
      name: "Da-Vinci`s Mystery",
    },
    slots127: {
      redirectUrl: "/casino/ezugi/DevilNumber",
      imgUrl: "./images/slots/Devil`s-Number.jpg",
      name: "Devil`s Number",
    },
    slots128: {
      redirectUrl: "/casino/ezugi/DiceDice",
      imgUrl: "./images/slots/Dice-Dice-Dice.jpg",
      name: "Dice Dice Dice",
    },
    slots129: {
      redirectUrl: "/casino/ezugi/FiveStarsPowerReels",
      imgUrl: "./images/slots/Five-Stars-Power-Reels.jpg",
      name: "Five Stars Power Reels",
    },
    slots130: {
      redirectUrl: "/casino/ezugi/GemsGoneWildPowerReels",
      imgUrl: "./images/slots/Gems-Gone-Wild-Power-Reels.jpg",
      name: "Gems Gone Wild Power Reels",
    },
    slots131: {
      redirectUrl: "/casino/ezugi/GemsGoneWild",
      imgUrl: "./images/slots/Gems-Gone-Wild.jpg",
      name: "Gems Gone Wild",
    },
    slots132: {
      redirectUrl: "/casino/ezugi/GoldenLeprechaunMegaways",
      imgUrl: "./images/slots/Golden-Leprechaun-Megaways.jpg",
      name: "GoldenLeprechaun Megaways",
    },
    slots132: {
      redirectUrl: "/casino/ezugi/HoardofPoseidon",
      imgUrl: "./images/slots/Hoard-of-Poseidon.jpg",
      name: "Hoard of Poseidon",
    },
    slots133: {
      redirectUrl: "/casino/ezugi/JewelScarabs",
      imgUrl: "./images/slots/Jewel-Scarabs.jpg",
      name: "Jewel Scarabs",
    },
    slots134: {
      redirectUrl: "/casino/ezugi/LegendofAthena",
      imgUrl: "./images/slots/Legend-of-Athena.jpg",
      name: "Legend of Athena",
    },

    slots135: {
      redirectUrl: "/casino/ezugi/LuckyHalloween",
      imgUrl: "./images/slots/Lucky-Halloween.jpg",
      name: "Lucky Halloween",
    },
    slots136: {
      redirectUrl: "/casino/ezugi/LuckyLittleDevil",
      imgUrl: "./images/slots/Lucky-Little-Devil.jpg",
      name: "Lucky Little Devil",
    },
    slots137: {
      redirectUrl: "/casino/ezugi/LuckyValentine",
      imgUrl: "./images/slots/Lucky-Valentine.jpg",
      name: "Lucky Valentine",
    },
    slots138: {
      redirectUrl: "/casino/ezugi/LuckyWizard",
      imgUrl: "./images/slots/Lucky-Wizard.jpg",
      name: "Lucky Wizard",
    },
    slots139: {
      redirectUrl: "/casino/ezugi/Masquerade",
      imgUrl: "./images/slots/Masquerade.jpg",
      name: "Masquerade",
    },
    slots140: {
      redirectUrl: "/casino/ezugi/MayanGods",
      imgUrl: "./images/slots/Mayan-Gods.jpg",
      name: "Mayan Gods",
    },
    slots141: {
      redirectUrl: "/casino/ezugi/MegaDragon",
      imgUrl: "./images/slots/Mega-Dragon.jpg",
      name: "Mega Dragon",
    },
    slots142: {
      redirectUrl: "/casino/ezugi/MegaJade",
      imgUrl: "./images/slots/Mega-Jade.jpg",
      name: "Mega Jade",
    },
    slots143: {
      redirectUrl: "/casino/ezugi/MegaRise",
      imgUrl: "./images/slots/Mega-Rise.jpg",
      name: "Mega Rise",
    },
    slots144: {
      redirectUrl: "/casino/ezugi/MultiplierRiches",
      imgUrl: "./images/slots/Multiplier-Riches.jpg",
      name: "Multiplier Riches",
    },
    slots145: {
      redirectUrl: "/casino/ezugi/MysteryReelsMegaways",
      imgUrl: "./images/slots/Mystery_Reels_Megaways.jpg",
      name: "Mystery Reels Megaways",
    },
    slots146: {
      redirectUrl: "/casino/ezugi/NinjaWays",
      imgUrl: "./images/slots/Ninja-Ways.jpg",
      name: "Ninja Ways",
    },
    slots147: {
      redirectUrl: "/casino/ezugi/PathofDestiny",
      imgUrl: "./images/slots/Path-of-Destiny.jpg",
      name: "Path of Destiny",
    },
    slots148: {
      redirectUrl: "/casino/ezugi/PersianFortune",
      imgUrl: "./images/slots/Persian-Fortune.jpg",
      name: "Persian Fortune",
    },
    slots149: {
      redirectUrl: "/casino/ezugi/ReelHeist",
      imgUrl: "./images/slots/Reel-Heist.jpg",
      name: "Reel Heist",
    },
    slots150: {
      redirectUrl: "/casino/ezugi/SnowWild",
      imgUrl: "./images/slots/Snow-Wild-And-The-7-Features.jpg",
      name: "Snow Wild And The 7 Features",
    },
    slots151: {
      redirectUrl: "/casino/ezugi/RobinHoodWildForest",
      imgUrl: "./images/slots/Robin-Hood`s-Wild-Forest.jpg",
      name: "Robin Hood`s Wild Forest",
    },

    slots152: {
      redirectUrl: "/casino/ezugi/SylvanSpirits",
      imgUrl: "./images/slots/Sylvan-Spirits.jpg",
      name: "Sylvan Spirits",
    },
    slots153: {
      redirectUrl: "/casino/ezugi/TheGreatestTrainRobbery",
      imgUrl: "./images/slots/The-Greatest-Train-Robbery.jpg",
      name: "The Greatest Train Robbery",
    },
    slots154: {
      redirectUrl: "/casino/ezugi/TheWildHatter",
      imgUrl: "./images/slots/The-Wild-Hatter.jpg",
      name: "The Wild Hatter",
    },
    slots155: {
      redirectUrl: "/casino/ezugi/ThorLightning",
      imgUrl: "./images/slots/Thor`s-Lightning.jpg",
      name: "Thor`s Lightning",
    },
    slots156: {
      redirectUrl: "/casino/ezugi/TikiFruits",
      imgUrl: "./images/slots/Tiki-Fruits.jpg",
      name: "Tiki Fruits",
    },
    slots157: {
      redirectUrl: "/casino/ezugi/TotemLightning",
      imgUrl: "./images/slots/Totem-Lightning.jpg",
      name: "Totem Lightning",
    },
    slots158: {
      redirectUrl: "/casino/ezugi/VaultCracker",
      imgUrl: "./images/slots/Vault-Cracker.jpg",
      name: "Vault Cracker",
    },
    slots159: {
      redirectUrl: "/casino/ezugi/VaultofAnubis",
      imgUrl: "./images/slots/Vault-of-Anubis.jpg",
      name: "Vault of Anubis",
    },
    slots160: {
      redirectUrl: "/casino/ezugi/WellofWishes",
      imgUrl: "./images/slots/Well-of-Wishes.jpg",
      name: "Well of Wishes",
    },
    slots161: {
      redirectUrl: "/casino/ezugi/WildCatsMultiline",
      imgUrl: "./images/slots/Wild-Cats-Multiline.jpg",
      name: "Wild Cats Multiline",
    },
    slots162: {
      redirectUrl: "/casino/ezugi/WildOClock",
      imgUrl: "./images/slots/Wild-O`Clock.jpg",
      name: "Wild O`Clock",
    },
    slots163: {
      redirectUrl: "/casino/ezugi/WildChest",
      imgUrl: "./images/slots/Wild-Wild-Chest.jpg",
      name: "Wild Wild Chest",
    },
    slots164: {
      redirectUrl: "/casino/ezugi/WinEscalator",
      imgUrl: "./images/slots/Win-Escalator.jpg",
      name: "Win Escalator",
    },
    slots165: {
      redirectUrl: "/casino/ezugi/YucatanMystery",
      imgUrl: "./images/slots/Yucatan`s-Mystery.jpg",
      name: "Yucatan`s Mystery",
    },
  },
  // wazdan: {
  //   game1: {
  //     redirectUrl: "/casino/worldcasino/game1",
  //     code: "WAZ-9coinsholdthejackpot",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/Artboard6.png",
  //   },
  //   game2: {
  //     redirectUrl: "/casino/worldcasino/game2",
  //     code: "WAZ-mysterykingdommysterybells",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/Artboard9.png",
  //   },
  //   game3: {
  //     redirectUrl: "/casino/worldcasino/game3",
  //     code: "WAZ-powerofsunsvarogeaster",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/Artboard4.png",
  //   },
  //   game4: {
  //     redirectUrl: "/casino/worldcasino/game4",
  //     code: "WAZ-sizzlingkingdombisoneaster",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/Artboard7.png",
  //   },
  //   game5: {
  //     redirectUrl: "/casino/worldcasino/game5",
  //     code: "WAZ-hotslot777coins",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/Artboard3.png",
  //   },
  //   game6: {
  //     redirectUrl: "/casino/worldcasino/game6",
  //     code: "WAZ-hotslot777cashout",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/Artboard8.png",
  //   },
  //   game7: {
  //     redirectUrl: "/casino/worldcasino/game7",
  //     code: "WAZ-burningsunholdthejackpot",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/Artboard5.png",
  //   },
  //   game8: {
  //     redirectUrl: "/casino/worldcasino/game8",
  //     code: "WAZ-15coinsholdthejackpotcashinfinity",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/Artboard1.jpg",
  //   },
  //   game9: {
  //     redirectUrl: "/casino/worldcasino/game9",
  //     code: "WAZ-12coinsholdthejackpotcashinfinity",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/Artboard2.png",
  //   },
  //   game10: {
  //     redirectUrl: "/casino/worldcasino/game10",
  //     code: "WAZ-backtothe70s",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/Backtothe70s.jpg",
  //   },
  //   game11: {
  //     redirectUrl: "/casino/worldcasino/game11",
  //     code: "WAZ-bars7s",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/BARs&7s.jpg",
  //   },
  //   game12: {
  //     redirectUrl: "/casino/worldcasino/game12",
  //     code: "WAZ-beachparty",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/BeachParty.jpg",
  //   },
  //   game13: {
  //     redirectUrl: "/casino/worldcasino/game13",
  //     code: "WAZ-beachpartyhot",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/BeachPartyHot.jpg",
  //   },
  //   game14: {
  //     redirectUrl: "/casino/worldcasino/game14",
  //     code: "WAZ-beautyfruity",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/BeautyFruity.jpg",
  //   },
  //   game15: {
  //     redirectUrl: "/casino/worldcasino/game15",
  //     code: "WAZ-bellwizard",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/BellWizard.jpg",
  //   },
  //   game16: {
  //     redirectUrl: "/casino/worldcasino/game16",
  //     code: "WAZ-blackhawk",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/BlackHawk.jpg",
  //   },
  //   game17: {
  //     redirectUrl: "/casino/worldcasino/game17",
  //     code: "WAZ-blackhawkdeluxe",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/BlackHawkDeluxe.jpg",
  //   },
  //   game18: {
  //     redirectUrl: "/casino/worldcasino/game18",
  //     code: "WAZ-blackhorse",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/BlackHorse.jpg",
  //   },
  //   game19: {
  //     redirectUrl: "/casino/worldcasino/game19",
  //     code: "WAZ-blackhorsedeluxe",
  //     casino: "wco",
  //     provider: "QT",
  //     homeUrl: "",
  //     imgUrl: "./images/slots/BlackHorseDeluxe.jpg",
  //   },
  // },
};
