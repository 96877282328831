import React, { useEffect } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { AiTwotoneCopy } from "react-icons/ai";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { APP_CONST } from "../../../../config/const";
import { createDepositRequestHandler } from "../../../../redux/user/actions";
import { Toaster } from "../../../../utils/toaster";
import { useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PaytmQr from "../../../../assets/images/paytmQr.jpeg";
export const Paytm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setAmount(location.state.amount);
  }, [location]);
  const initialForm = {
    name: "",
    walletNo: "",
    amount: 0,
    txnId: "",
  };

  const [formData, setFormData] = useState(initialForm);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    let data = { ...formData, [e.target.name]: e.target.value };
    validator(data);
    setFormData(data);
  };

  const validator = (payload) => {
    setFormErrors({});
    let errors = {};

    const dataToValidate = payload ? payload : formData;
    const { txnId } = dataToValidate;
    if (!txnId || txnId == "") {
      errors.txnId = "TransactionId is required";
    } else if (txnId.length !== 10) {
      errors.txnId = "TransactionId must be of 10 digits";
    }
    setFormErrors(errors);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = validator(formData);
    if (Object.keys(formErrors).length === 0) {
      if (amount >= 0.91 && amount <= 25000) {
        setLoading(true);
        await createDepositRequestHandler({
          ...formData,
          gateway: APP_CONST.GATEWAYS.M_PAYTM,
          amount,
        })
          .then((res) => {
            setLoading(false);
            // setIsRequesting(false);
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Request success");
            navigate("/cashier");
          })
          .catch((err) => {
            // setIsRequesting(false);
            // console.log(err);
            setLoading(false);
            Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
          });
        setFormData(initialForm);
        return;
      } else {
        alert("Amount must be greater than 300 and less than 25000");
      }
    }
  };
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className=" container">
          <div className="d-flex align-items-center justify-content-between">
            <h2 class="sectionTitle">Account Deposit </h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <a href="javascript:void(0)" className="copy-name">
                <h5>Pay On Number</h5>
                <p>
                  9101025082
                  <CopyToClipboard
                    text="9101025082"
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text copied");
                    }}
                  >
                    <AiTwotoneCopy />
                  </CopyToClipboard>
                </p>
              </a>
            </div>
            <div className="col-md-6 col-12">
              <a href="javascript:void(0)" className="copy-name">
                <h5>Upi Id</h5>
                <p>
                  9101025082@paytm
                  <CopyToClipboard
                    text=" 9101025082@paytm"
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text copied");
                    }}
                  >
                    <AiTwotoneCopy />
                  </CopyToClipboard>
                  {/* <p>(PLEASE ONLY DO SEND MONEY)</p> */}
                </p>
              </a>
            </div>
          </div>
          <div className="tron-qr-section paytm">
            <div className="tron-form">
              <h2 class="sectionTitle">Request a Deposit</h2>
              <div className="deposit-form mt-2">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      type="number"
                      placeholder="Amount*"
                      name="amount"
                      value={amount}
                      required
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      // onChange={handleChange}
                      isInvalid={formErrors?.amount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors?.amount}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Control
                      type="text"
                      placeholder="Your Paytm Wallet Number*"
                      name="walletNo"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Control
                      type="text"
                      placeholder="Your Name*"
                      name="name"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Control
                      type="text"
                      placeholder="TRX ID (For ex.: AA73ER75GJ) "
                      name="txnId"
                      required
                      onChange={handleChange}
                      isInvalid={formErrors?.txnId}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors?.txnId}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
                <div className="payment-button">
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Make Payment"}
                  </Button>
                  <Button variant="danger">Return</Button>
                </div>
              </div>
            </div>
            <div className="tron-qr-code paytm">
              <div className="qr-code-img text-center">
                <img src={PaytmQr} />
              </div>
              <div className="tron-qr-content">
                {/* <p>USDT (TRC 20) = 102.9847602 USD</p> */}
                <p className="copy-name">
                  Name - Abdul Hasim Choudhury
                  <div className="d-flex justify-content-between">
                    <span>
                      <b>Pay me on Paytm - https://p.paytm.me/xCTH/jbmfosaz</b>
                    </span>
                    <CopyToClipboard
                      text=" https://p.paytm.me/xCTH/jbmfosaz"
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    >
                      <AiTwotoneCopy style={{ cursor: "pointer" }} />
                    </CopyToClipboard>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
