import React, { Suspense, useEffect } from "react";
import "./App.scss";
import Footer from "./containers/Footer";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./routing/ProtectedRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails } from "./redux/app/actions";
import PublicSportsBook from "./views/pages/privatePages/sportsBook";
import AMLPolicy from "./views/pages/publicPages/BeforeFooterPages/AMLpolicy";
import BeforeBettingRules from "./views/pages/publicPages/BeforeFooterPages/BeforeBettingRules";
import TermsConditions from "./views/pages/publicPages/BeforeFooterPages/TermsConditions";
import ContactUs from "./views/pages/privatePages/FooterPages/ContactUs";
import { Bkash } from "./views/pages/privatePages/deposit/Bkash";
import { Nagad } from "./views/pages/privatePages/deposit/Nagad";
import { BkashWitdraw } from "./views/pages/privatePages/withdraw/BkashWitdraw";
import { NagadWithdraw } from "./views/pages/privatePages/withdraw/NagadWithdraw";
import TronConfirm from "./views/pages/privatePages/deposit/TronConfirm";
import TronQRCode from "./views/pages/privatePages/deposit/TronQRCode";
import BSCConfirm from "./views/pages/privatePages/deposit/BSCConfirm";
import BSCQRcode from "./views/pages/privatePages/deposit/BSCQRcode";
import TronConfirmWithdraw from "./views/pages/privatePages/withdraw/TronConfirmWithdraw";
import TronQRCodeWithdraw from "./views/pages/privatePages/withdraw/TronQRCodeWithdraw";
import BSCConfirmWithdraw from "./views/pages/privatePages/withdraw/BSCConfirmWithdraw";
import BSCQRcodeWithdraw from "./views/pages/privatePages/withdraw/BSCQRcodeWithdraw";
import Worldcasino from "./views/pages/privatePages/gamePage/Worldcasino";
import EzugiPage from "./views/pages/privatePages/gamePage/EzugiPage";
import EvolutionPage from "./views/pages/privatePages/gamePage/EvolutionPage";
import ResponsibleGame from "./views/pages/privatePages/FooterPages/ResponsibleGame";
import BeforeLoginFooter from "./containers/BeforeLoginFooter";
import BeforeContact from "./views/pages/publicPages/BeforeFooterPages/BeforeContact";
import BeforeResponsibleGame from "./views/pages/publicPages/BeforeFooterPages/BeforeResponsibleGame";
import BeforeFairPlay from "./views/pages/publicPages/BeforeFooterPages/BeforeFairPlay";
import AMLpolicyss from "./views/pages/privatePages/FooterPages/AMLpolicyss";
import FairPlay from "./views/pages/privatePages/FooterPages/FairPlay";
import BettingRules from "./views/pages/privatePages/FooterPages/BettingRules";
import TermsConditionsss from "./views/pages/privatePages/FooterPages/TermsConditionss";
import { Paytm } from "./views/pages/privatePages/deposit/Paytm";
import { PaytmWithdraw } from "./views/pages/privatePages/withdraw/PaytmWithdraw";
import Slots from "./views/pages/privatePages/Slots";
import DepositGateway from "./views/pages/privatePages/deposit/depositGateway";
import GatewaysList from "./views/pages/privatePages/deposit/gatewaysList";

const Home = React.lazy(() => import("./views/pages/privatePages/home"));
const Games = React.lazy(() => import("./views/pages/privatePages/games"));
const DynamicCasinoPage = React.lazy(() =>
  import("./views/pages/privatePages/games/DynamicCasinoPage")
);
const Cashier = React.lazy(() => import("./views/pages/privatePages/cashier"));
const LandingPage = React.lazy(() =>
  import("./views/pages/publicPages/landingPage")
);
const Deposit = React.lazy(() => import("./views/pages/privatePages/deposit"));
const Withdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdraw")
);
const Register = React.lazy(() => import("./views/pages/publicPages/register"));
const Login = React.lazy(() => import("./views/pages/publicPages/login"));
const MobileNumberVerify = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumberVerify")
);
const MobileNumber = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumber")
);
const Profile = React.lazy(() => import("./views/pages/privatePages/profile"));
const Sports = React.lazy(() => import("./views/pages/privatePages/sports"));
const ExchangeLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeLobbyPage")
);
const MatchDetailPage = React.lazy(() =>
  import("./views/pages/privatePages/sports/matchDetailPage")
);
const MyBets = React.lazy(() => import("./views/pages/privatePages/myBets"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword/resetPassword")
);
const Betby = React.lazy(() => import("./views/pages/privatePages/betby"));
const DepositGateways = React.lazy(() =>
  import("./views/pages/privatePages/deposit/gateways")
);
const WithdrawGateways = React.lazy(() =>
  import("./views/pages/privatePages/withdraw/Gateways")
);
const ExchangeFund9Wicket = React.lazy(() =>
  import("./views/components/ExchangeFund9Wicket")
);

const App = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppDetails());
  }, []);
  useEffect(() => {
    document.title = appDetails?.SEO_TITLE || "";
  }, [appDetails]);
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoutes isAuth={isAuth}>
                <LandingPage />
                <BeforeLoginFooter />
              </PublicRoutes>
            }
          />
          <Route
            path="/home"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Home />
                <ExchangeFund9Wicket />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/games-all"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Games />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug/:gameslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/cashier"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Cashier />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/mybets"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MyBets />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit/:gateway"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DepositGateway />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gatewaylist"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GatewaysList />
                <Footer />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Deposit />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route path="/sportsbook" element={<Sports isAuth={isAuth} />} />
          {/* <Route
            path="/public-sports"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Sports isAuth={isAuth} />
              </PublicRoutes>
            }
          /> */}
          <Route
            path="/withdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Withdraw />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Register />
                <Footer />
              </PublicRoutes>
            }
          />
          <Route
            path="/sign-in"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path="/verify-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumberVerify />
              </PublicRoutes>
            }
          />
          <Route
            path="/mobile-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumber />
              </PublicRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Profile />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route path="test" element={<Sports />} />
          {/*<Route
            // path="/sports/exchange"
            path="/sportsbook"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeLobbyPage />
              </ProtectedRoutes>
            }
          />*/}
          <Route
            path="/detail-page/:slug/:matchId/:marketId/:sportId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MatchDetailPage isAuth={isAuth} />
                {isAuth ? <Footer /> : <BeforeLoginFooter />}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/AMLpolicy"
            element={
              <PublicRoutes isAuth={isAuth}>
                <AMLPolicy />
              </PublicRoutes>
            }
          />
          <Route
            path="/Bettingrules"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeBettingRules />
              </PublicRoutes>
            }
          />
          <Route
            path="/termscondition"
            element={
              <PublicRoutes isAuth={isAuth}>
                <TermsConditions />
              </PublicRoutes>
            }
          />
          <Route
            path="/contact"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ContactUs />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ForgotPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ResetPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/bkash"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Bkash />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/paytm"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Paytm />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bkashwithdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BkashWitdraw />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/paytmwithdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PaytmWithdraw />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/nagad"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Nagad />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/nagadwithdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <NagadWithdraw />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit/:mode"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DepositGateways />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdraw/:mode"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawGateways />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/TronConfirm"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <TronConfirm />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/TronConfirmWithdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <TronConfirmWithdraw />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bet-by"
            element={
              <PublicRoutes isAuth={isAuth}>
                {/* <LandingPage /> */}
                <Betby isAuth={isAuth} />
                <BeforeLoginFooter />
              </PublicRoutes>
            }
          />
          <Route
            path="/betby"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Betby isAuth={isAuth} />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/betby"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Betby isAuth={isAuth} />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/Slots"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Slots />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/TronQRCode"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <TronQRCode />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/TronQRCodeWithdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <TronQRCodeWithdraw />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/BSCConfirm"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BSCConfirm />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/BSCConfirmWithdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BSCConfirmWithdraw />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/BSCQRcode"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BSCQRcode />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/BSCQRcodeWithdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BSCQRcodeWithdraw />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/Worldcasino"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Worldcasino />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/EzugiPage"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <EzugiPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/EvolutionPage"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <EvolutionPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/ResponsibleGame"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ResponsibleGame />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/contact-us"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeContact />
              </PublicRoutes>
            }
          />
          <Route
            path="/responsible-game"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeResponsibleGame />
              </PublicRoutes>
            }
          />
          <Route
            path="/fair-play"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeFairPlay />
              </PublicRoutes>
            }
          />
          <Route
            path="/amlpolicyss"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <AMLpolicyss />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/fairplay"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <FairPlay />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/Betting-rules"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BettingRules />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/terms-conditions"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <TermsConditionsss />
              </ProtectedRoutes>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
