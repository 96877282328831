import React, { useEffect, useState } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import ProfileIcon from "../assets/svg/ProfileIcon";
import logo from "../assets/svg/logo_new.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { AiFillStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import { APP_CONST } from "../config/const";
import { socket } from "../utils/socketUtil";
import { Toaster } from "../utils/toaster";
import Loader from "../assets/images/games-tab/loader-new.gif";
import { useLocation } from "react-router-dom";

import Visa from "../assets/images/providers/upi/visa.svg";
import Tether from "../assets/images/providers/upi/tether.svg";
import Bitcoin from "../assets/images/providers/upi/bitcoin.svg";
import Skrill from "../assets/images/providers/upi/skrill.svg";
import Astropay from "../assets/images/providers/upi/astropay.png";
import Phonepay from "../assets/images/providers/upi/phonepa.png";
import Netbank from "../assets/images/providers/upi/netbank.png";
import Gpay from "../assets/images/providers/upi/gpay.png";
import Mastercard from "../assets/images/providers/upi/mastercard.svg";
import Paytm from "../assets/images/providers/upi/paytm.png";
import rupay from "../assets/images/providers/upi/rupay.png";
import neteller from "../assets/images/providers/upi/neteller.png";
const Footer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoader, setShowLoader] = useState(false);
  const { appDetails } = useSelector((state) => state.app);
  const handleExchangeLaunch = () => {
    setShowLoader(true);
    socket.emit("get:spbwicket9_lobby", {}, (res) => {
      if (res.data?.error === true) {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
        return false;
      }
      setShowLoader(false);
      window.open(res?.data?.data?.lobbyUrl, "_blank");
    });

    return () => {
      socket.off("get:spbwicket9_lobby");
    };
  };
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const footerPages = [
    "home",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "deposit",
    "cashier",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);
  return (
    <footer className="footer">
      <div className={isLoader ? "loading_img2" : "loading_img2 d-none"}>
        <img src={Loader} alt="Loader" />
      </div>
      <div className="upi-provider">
        <ul>
          <li>
            <a href="#">
              <img src={Visa} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={Tether} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={Paytm} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={rupay} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={neteller} />
            </a>
          </li>

          <li>
            <a href="#">
              <img src={Bitcoin} />
            </a>
          </li>

          <li>
            <a href="#">
              <img src={Skrill} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={Astropay} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={Phonepay} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={Netbank} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={Gpay} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={Mastercard} />
            </a>
          </li>
        </ul>
      </div>
      <div className="container-fluid">
        <div className="footer-links mb-5">
          <div>
            <div className="footer-logo">
              {appDetails?.LOGO_URL && (
                <img src={appDetails?.LOGO_URL || logo} />
              )}
            </div>
            <p>{appDetails?.FOOTER_TEXT || ""}</p>
          </div>
          <div className="d-flex">
            <div className="betlinks">
              <h4>1st Bet</h4>
              <ul>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
                <li>
                  <a href="/home">AML Policy</a>
                </li>
                <li>
                  <a href="/home">Responsible Gaming</a>
                </li>
              </ul>
            </div>
            <div className="betlinks">
              <h4>Usage and Terms</h4>
              <ul>
                <li>
                  <a href="/home">Fair Play</a>
                </li>
                <li>
                  <a href="/home">Betting Rules</a>
                </li>
                <li>
                  <a href="/home">Terms and Conditions</a>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <div className="secureGaming">
              <ul>
                <li>
                  <img src="./images/GA-partner.png" />
                </li>
                <li>
                  <img src="./images/gamecare-partner.png" />
                </li>
                <li>
                  <a
                    href="https://www.mga.org.mt/licensee-hub/licensee-register/"
                    target="_blank"
                  >
                    <img src="./images/mga-partner.png" />
                  </a>
                </li>
                <li>
                  <img src="./images/age18.png" />
                </li>
              </ul>
            </div>
            <div className="paymentImages mb-4">
              <ul>
                <li>
                  <img src="./images/bkash1.png" />
                </li>
                <li>
                  <img src="./images/USDT-logo.png" />
                </li>
                <li>
                  <img src="./images/paytm.png" />
                </li>
              </ul>
            </div>

            <div className="social-icons">
              <h3>For Support</h3>
              <ul>
                <li>
                  <a
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? appDetails?.WHATS_APP_URL
                        : "javascript:void(0)"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    {/* <span className="notification-count">2</span> */}
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails.TELEGRAM != ""
                        ? appDetails.TELEGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="d-md-none d-block">
          <div className="app-img" onClick={handleShow}>
            <img src="./images/google-play.png" alt="Google PlayStore Logo" />
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="app-modal"
          >
            <Modal.Body closeButton>
              <div className="app-installed">
                <div className="app-left-part">
                  <Button variant="secondary" onClick={handleClose}>
                    X
                  </Button>
                  <div className="app-body">
                    <div className="app-logo">
                      {appDetails?.LOGO_URL && (
                        <img
                          src={appDetails?.LOGO_URL || logo}
                          alt="App Logo"
                        />
                      )}
                    </div>
                    <a className="app-name">
                      <h5>1stbet</h5>
                      <div className="app-rate">
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <AiFillStar />
                        <BsStarHalf />
                      </div>
                      <a
                        href="./images/0TtatjwLdZwgvIkSmZxS.apk"
                        download="0TtatjwLdZwgvIkSmZxS.apk"
                      >
                        Download the app
                      </a>
                    </a>
                  </div>
                </div>

                <div className="app-install-btn">
                  <a
                    href="./images/0TtatjwLdZwgvIkSmZxS.apk"
                    download="0TtatjwLdZwgvIkSmZxS.apk"
                  >
                    <button type="button" className="installBtn">
                      INSTALL
                    </button>
                  </a>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li onClick={() => handleExchangeLaunch()}>
              <a
                className={activePage === "sportsbook" ? "active" : ""}
                href="/betby"
              >
                <SportsIcon />
                <span>Sportsbook</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "casino/ezugi" ? "active" : ""}
                href="/casino/worldcasino"
              >
                <GamesIcon />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a className={activePage === "home" ? "active" : ""} href="/home">
                <HomeIcon />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "cashier" ? "active" : ""}
                href="/cashier"
              >
                <CashierIcon />
                <span>Cashier</span>
              </a>
            </li>
            <li>
              <a href={"https://wa.me/+380947117207"} target="_blank">
                {/* <HeartIcon /> */}
                <ProfileIcon />
                <span>Support</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
