import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

export class AMLpolicys extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <HeaderAfterLogin />
        </div>
        <main className="main page-content">
          <div className="container">
            <h3 className="page-heading">AML Policy</h3>
            <div className="">
              <h5>Policy approval</h5>
              <p>
                Saipro Telecom LLC . (the ‘Company’) (‘We’) is a company
                registered and incorporated in Ukraine with Company
                registration: 44689335
              </p>
              <p>
                The company is further approved to accept and transact with
                customers in either fiat (traditional currencies), crypto
                (digital or virtual) currencies or a combination of both.
              </p>
              <p>
                We are committed to preventing our systems from being used for
                Money Laundering, Terrorist Financing or other crime. This
                includes auctioning the following steps:
              </p>
              <ul>
                <li>General provisions</li>
                <p>
                  We are fully committed to applying measures to prevent Money
                  Laundering and Combatting Terrorism Financing. We are also
                  committed to our social duty in preventing our systems from
                  being used as a tool for crime.
                </p>
                <p>
                  We will endeavor to stay updated with developments in
                  prevention measures in order to protect the organization, its
                  operations and reputation.
                </p>
                <p>
                  Our policies and procedures were built and continue to be
                  updated to observe the laws relevant to our operations.
                </p>
              </ul>
              <ul>
                <li>Customer due diligence</li>
                <p>
                  We will apply appropriate customer due diligence measures
                  required by law, including utilizing identifiers.
                </p>
                <p>
                  We will apply due diligence checks as part of its customer
                  registration procedures, verifying age, name, residential
                  address, nationality, PEP and a sanctions check.
                </p>
                <p>
                  At any time a customer’s risk profile changes based upon any
                  number of ‘red flags’, we will conduct enhanced due diligence,
                  including verification based on personal documents, and
                  publicly held data, privilege information from other operators
                  and sources, financial or corporate data, and third-party data
                  providers.
                </p>
                <p>
                  We have a duty to monitor all customer relationships in
                  accordance with industry best practice, international
                  recommendations and guidelines.
                </p>
                <p>
                  Any suspicious transactions or circumstances potentially
                  related to Money Laundering and Terrorist Financing will be
                  reported to the relevant law enforcement authority.
                </p>
                <p>
                  Where due diligence checks cannot be completed, we will
                  suspend the business relationship until such time as all
                  checks have been satisfactorily completed.
                </p>
              </ul>
              <ul>
                <li>Cryptocurrency transactions</li>
                <p>
                  The company is entitled to perform additional background and
                  security checks for the customers wishing to deposit or
                  withdraw using cryptocurrencies. In case of suspicion, the
                  company has the right to suspend the customer account and
                  request additional Due Diligence documentation from the
                  customer.
                </p>
                <p>
                  If we are not able to be assured of either the identity of the
                  customer or the source of funding, the matter can be referred
                  to the management board and service provider for review and
                  final decision.
                </p>
                <p>
                  If the decision is to terminate the relationship any retained
                  customer funds will be held in a seized funds account and
                  declared as part of any suspicious activity report made to law
                  enforcement.
                </p>
              </ul>
              <ul>
                <li>Reporting suspicions</li>
                <p>
                  A customer being identified as being on a sanctions list,
                  linked to Money Laundering, the Financing of Terrorism, or
                  other criminal activity, may warrant the submission of a
                  formal suspicious activity report to law enforcement.
                </p>
              </ul>
            </div>
          </div>
        </main>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default AMLpolicys;
