import {
  SPORTS_DATA,
  ODDS_DATA,
  BET_SLIP,
  BET_SLIP_CONTENT,
  SET_SESSION_DATA,
  SET_SESSION_ODDS,
  BET_MATCHES,
  USER_BETS,
  MATCH_BETS,
  BOOKMAKER_DATA,
} from "./type";

const initialState = {
  sportsData: sessionStorage.getItem("sportsData")
    ? JSON.parse(sessionStorage.getItem("sportsData"))
    : null,
  oddsData: sessionStorage.getItem("oddsData")
    ? JSON.parse(sessionStorage.getItem("oddsData"))
    : null,
  showBetSlip: false,
  betSlipContent: null,
  sessionData: null,
  AllMarketData: null,
  sessionOdds: null,
  betMatches: null,
  userBets: null,
  matchBets: null,
  bookMakerData: null,
};

export default (state = initialState, actions) => {
  let { payload, type } = actions;
  switch (type) {
    case SPORTS_DATA:
      sessionStorage.setItem("sportsData", JSON.stringify(payload));
      return {
        ...state,
        sportsData: payload,
      };
    case ODDS_DATA:
      sessionStorage.setItem("oddsData", JSON.stringify(payload));
      return {
        ...state,
        oddsData: payload,
      };
    case BET_SLIP:
      return {
        ...state,
        showBetSlip: payload,
      };
    case BET_SLIP_CONTENT:
      return {
        ...state,
        betSlipContent: payload,
      };
    case SET_SESSION_DATA:
      const sessionArray = [];
      const allMarketArray = [];
      if (payload.length) {
        payload.forEach((element) => {
          if (
            // !element.HeadName.toLowerCase().includes("adv") &&
            !element.HeadName.toLowerCase().includes("bookmaker") &&
            !element.HeadName.toLowerCase().includes("match odds") &&
            !element.HeadName.toLowerCase().includes("toss") &&
            !element.HeadName.toLowerCase().includes("tied match")
          ) {
            if (element.result === null || element.result === "null") {
              if (element.HeadName.toLowerCase().includes("over")) {
                sessionArray.push(element);
              } else {
                allMarketArray.push(element);
              }
            }
          }
        });
      }
      return {
        ...state,
        sessionData: sessionArray,
        AllMarketData: allMarketArray,
      };
    case SET_SESSION_ODDS:
      return {
        ...state,
        sessionOdds: payload,
      };
    case BET_MATCHES:
      return {
        ...state,
        betMatches: payload,
      };
    case USER_BETS:
      return {
        ...state,
        userBets: payload,
      };
    case MATCH_BETS:
      return {
        ...state,
        matchBets: payload,
      };
    case BOOKMAKER_DATA:
      return {
        ...state,
        bookMakerData: payload,
      };
    default:
      return state;
  }
};
