import { slotsData } from "./slots.data";

export const SlotGames = {
  // ChilliHeat: {
  //   href: "/casino/worldcasino/ChilliHeat",
  //   code: "vs25chilli",
  //   casino: "wco",
  //   provider: "PP",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/chilli-head.png", alt: "" },
  // },
  // BiggerBass: {
  //   href: "/casino/worldcasino/BiggerBass",
  //   code: "vs10bbbonanza",
  //   casino: "wco",
  //   provider: "PP",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/biggerbass.png", alt: "" },
  // },
  // fortunegiza: {
  //   href: "/casino/worldcasino/fortunegiza",
  //   code: "vs20amuleteg",
  //   casino: "ezugi",
  //   provider: "",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/fortunegiza.png", alt: "" },
  // },
  // sweetbonanza: {
  //   href: "/casino/wco/sweetbonanza",
  //   code: "1101",
  //   casino: "wco",
  //   provider: "PP",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanza.png", alt: "" },
  // },
  // sweetbonanza: {
  //   href: "/casino/evolution/sweetbonanza",
  //   code: "1",
  //   casino: "wco",
  //   provider: "XPG",
  //   homeUrl: "",
  //   cover: { src: "./images/roulette/casino033.png", alt: "" },
  // },
  // sweetbonanza: {
  //   href: "/casino/worldcasino/sweetbonanza",
  //   code: "vs20fruitsw",
  //   casino: "wco",
  //   provider: "PP",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanza.png", alt: "" },
  // },
  // sweetbonanzaxmas: {
  //   href: "/casino/worldcasino/sweetbonanzaxmas",
  //   code: "vs20sbxmas",
  //   casino: "wco",
  //   provider: "PP",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // lionways: {
  //   href: "/casino/worldcasino/lionways",
  //   code: "vs243lionsgold",
  //   casino: "wco",
  //   provider: "PP",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/lionmegaways.png", alt: "" },
  // },
};

export const WordCasinoGames = {
  ...slotsData.wazdan,
  // baccarat: {
  //   href: "/casino/worldcasino/baccarat",
  //   code: "",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/roulette/speed-roulette.png", alt: "" },
  // },
  // sweetbonanza: {
  //   href: "/casino/sweetbonanza",
  //   code: "",
  //   casino: "wco",
  //   provider: "PP",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanza.png", alt: "" },
  // },
  // ChilliHead: {
  //   href: "/casino/worldcasino/ChilliHead",
  //   code: "",
  //   casino: "wco",
  //   provider: "PP",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/chilli-head.png", alt: "" },
  // },
  // BiggerBass: {
  //   href: "/casino/ezugi/BiggerBass",
  //   code: "",
  //   casino: "wco",
  //   provider: "PP",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/chilli-head.png", alt: "" },
  // },
  // fortunegiza: {
  //   href: "/casino/ezugi/fortunegiza",
  //   code: "",
  //   casino: "wco",
  //   provider: "PP",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/fortunegiza.png", alt: "" },
  // },
  // sweetbonanzaxmas: {
  //   href: "/casino/wco/sweetbonanzaxmas",
  //   code: "",
  //   casino: "wco",
  //   provider: "PP",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // lionways: {
  //   href: "/casino/worldcasino/lionways",
  //   code: "",
  //   casino: "wco",
  //   provider: "PP",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // TeenPatti3WC: {
  //   href: "/casino/worldcasino/TeenPatti3WC",
  //   code: "D3TP",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // GoaAndarbaharWC: {
  //   href: "/casino/worldcasino/GoaAndarbaharWC",
  //   code: "AB2",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // kingraceWC: {
  //   href: "/casino/worldcasino/kingraceWC",
  //   code: "CR",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // worlimatkaWC: {
  //   href: "/casino/worldcasino/worlimatkaWC",
  //   code: "WM",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // teenpatti2020WC: {
  //   href: "/casino/worldcasino/teenpatti2020WC",
  //   code: "VTP20",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // classicABWC: {
  //   href: "/casino/worldcasino/classicABWC",
  //   code: "AB",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // BaccaratWC: {
  //   href: "/casino/worldcasino/BaccaratWC",
  //   code: "BAC",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // teenpattiWC: {
  //   href: "/casino/worldcasino/teenpattiWC",
  //   code: "TP",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // dragonTigerWC: {
  //   href: "/casino/worldcasino/dragonTigerWC",
  //   code: "DT7M",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // lucky7WC: {
  //   href: "/casino/worldcasino/lucky7WC",
  //   code: "UD7",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // AndarBaharWC: {
  //   href: "/casino/worldcasino/AndarBaharWC",
  //   code: "ABC",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // teenpattifaceOffWC: {
  //   href: "/casino/worldcasino/teenpattifaceOffWC",
  //   code: "TPFO",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // rouletteWC: {
  //   href: "/casino/worldcasino/rouletteWC",
  //   code: "RT",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // AkbarRomeoWC: {
  //   href: "/casino/worldcasino/AkbarRomeoWC",
  //   code: "ARW",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // card32WC: {
  //   href: "/casino/worldcasino/card32WC",
  //   code: "C32",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // PokerWC: {
  //   href: "/casino/worldcasino/PokerWC",
  //   code: "VPK",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },
  // muflisTPWC: {
  //   href: "/casino/worldcasino/muflisTPWC",
  //   code: "MTP7M",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/slots/sweetbonanzaxmas.png", alt: "" },
  // },

  // spribe: {
  //   href: "/casino/sprie",
  //   code: "",
  //   provider: "spribe",
  //   homeUrl: "",
  //   games: {
  //     mines: {
  //       href: "/casino/spribe/mines",
  //       code: "mines",
  //       casino: "spribe",
  //       provider: "mines",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/02.jpg", alt: "" },
  //     },
  //     goal: {
  //       href: "/casino/spribe/goal",
  //       code: "goal",
  //       casino: "spribe",
  //       provider: "goal",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/06.png", alt: "" },
  //     },
  //     dice: {
  //       href: "/casino/spribe/dice",
  //       code: "dice",
  //       casino: "spribe",
  //       provider: "dice",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/07.jpg", alt: "" },
  //     },
  //     aviator: {
  //       href: "/casino/spribe/aviator",
  //       code: "aviator",
  //       casino: "spribe",
  //       provider: "aviator",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/03.png", alt: "" },
  //     },
  //     pilnko: {
  //       href: "/casino/spribe/pilnko",
  //       code: "pilnko",
  //       casino: "spribe",
  //       provider: "pilnko",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/08.jpg", alt: "" },
  //     },
  //     miniroulette: {
  //       href: "/casino/spribe/miniroulette",
  //       code: "miniroulette",
  //       casino: "spribe",
  //       provider: "miniroulette",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/04.png", alt: "" },
  //     },
  //     hilo: {
  //       href: "/casino/spribe/hilo",
  //       code: "hilo",
  //       casino: "miniroulette",
  //       provider: "hilo",
  //       homeUrl: "",
  //       cover: { src: "./images/spribe/05.png", alt: "" },
  //     },
  //   },
  // },

  // Slots: {
  //   twinspin: {
  //     href: "/casino/ezugi/twinspin",
  //     code: "228001",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "",
  //     cover: { src: "./images/slots/01.jpg", alt: "" },
  //   },
  //   gonzoquest: {
  //     href: "/casino/ezugi/gonzoquest",
  //     code: "228001",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "",
  //     cover: { src: "./images/slots/02.jpg", alt: "" },
  //   },

  //   asgardianstones: {
  //     href: "/casino/ezugi/asgardianstones",
  //     code: "228001",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "",
  //     cover: { src: "./images/slots/03.jpg", alt: "" },
  //   },
  //   secrets: {
  //     href: "/casino/ezugi/secrets",
  //     code: "228001",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "",
  //     cover: { src: "./images/slots/04.jpg", alt: "" },
  //   },
  //   grandwheel: {
  //     href: "/casino/ezugi/grandwheel",
  //     code: "228001",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "",
  //     cover: { src: "./images/slots/05.jpg", alt: "" },
  //   },
  // },
};
