import React from 'react'

export default function SportsIcon() {
  return (
    <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24.333"
                                height="24.333"
                                viewBox="0 0 24.333 24.333">
                                <g id="football" transform="translate(-2 -2)">
                                    <path
                                        id="Path_1593"
                                        data-name="Path 1593"
                                        d="M14.167,2A12.167,12.167,0,1,0,26.333,14.167,12.181,12.181,0,0,0,14.167,2Zm8.982,17.44a.863.863,0,0,0-.291-.059h-3.88L17.75,17.542l1.468-3.671,2.114-1.41,3.226,2.419A10.343,10.343,0,0,1,23.148,19.44ZM5.476,19.381a.863.863,0,0,0-.291.059A10.348,10.348,0,0,1,3.878,15.79l3.24-2.477,2.046.682,1.419,3.547L9.357,19.381ZM5.035,9.14l1.084,2.749-2.356,1.8A10.359,10.359,0,0,1,5.035,9.14Zm7.113,7.634-1.307-3.267,3.326-3.326,3.326,3.326-1.307,3.267ZM22.278,11l.841-2.151a10.355,10.355,0,0,1,1.351,3.8Zm-.379-3.8-1.481,3.785v0l-1.8,1.2L15.036,8.593V6.641l3.121-2.107A10.469,10.469,0,0,1,21.9,7.194ZM15.98,3.906,14.134,5.153,12.464,3.89a9.732,9.732,0,0,1,3.515.017Zm-5.628.564L13.3,6.7V8.593L9.587,12.3l-1.654-.551L6.226,7.423A10.463,10.463,0,0,1,10.352,4.471ZM6.416,21.119H9.161l.7,2.536A10.467,10.467,0,0,1,6.416,21.119Zm5.44,3.209L10.765,20.4l1.26-1.889h4.284L17.567,20.4l-1.1,3.932a10.139,10.139,0,0,1-4.609,0Zm6.6-.668.712-2.541h2.747A10.476,10.476,0,0,1,18.458,23.661Z"
                                        />
                                </g>
                            </svg>

  )
}