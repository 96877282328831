import React from "react";

export default function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.333"
      height="23.415"
      viewBox="0 0 25.333 23.415"
    >
      <g id="ic-home" transform="translate(-15.33 -13.88)">
        <g id="Group_9" data-name="Group 9" transform="translate(15.33 13.88)">
          <g id="Group_7" data-name="Group 7">
            <path
              id="Path_15"
              data-name="Path 15"
              d="M15.868,27.147a.532.532,0,0,1-.38-.158.54.54,0,0,1,0-.764L27.675,14.038a.54.54,0,0,1,.764,0L40.505,26.1a.54.54,0,1,1-.764.764L28.055,15.185l-11.807,11.8A.532.532,0,0,1,15.868,27.147Z"
              transform="translate(-15.33 -13.88)"
              fill="#ffffff"
            />
          </g>
          <g id="Group_8" data-name="Group 8" transform="translate(2.99 3.698)">
            <path
              id="Path_16"
              data-name="Path 16"
              d="M41.749,42.927H35.106a.538.538,0,0,1-.538-.538v-5.3H31.047v5.292a.538.538,0,0,1-.538.538H23.818a.538.538,0,0,1-.538-.538v-9.1a.535.535,0,0,1,.158-.38l9.039-9.039a.567.567,0,0,1,.384-.158.548.548,0,0,1,.384.162l8.888,9.069a.546.546,0,0,1,.154.376v9.069A.541.541,0,0,1,41.749,42.927Z"
              transform="translate(-23.28 -23.71)"
              fill="none"
              stroke="#ffffff"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
