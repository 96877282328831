import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

export class TermsConditionsss extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <HeaderAfterLogin />
        </div>
        <main className="main page-content">
          <div className="container">
            <h3 className="page-heading">Terms & Conditions</h3>
            <div className="">
              <h3>1. General</h3>
              <p>
                1.1. These terms and conditions (“Terms and Conditions”) apply
                to the usage of games accessible through 1stbet
              </p>
              <p>1.2. 1stbet is owned and operated by Saipro Telecom LLC</p>
              <p>
                1.3. These Terms and Conditions come into force as soon as you
                complete the registration process, which includes checking the
                box accepting these Terms and Conditions and successfully
                creating an account. By using any part of the Website following
                account creation, you agree to these Terms and Conditions
                applying to the use of the Website.
              </p>
              <p>
                1.4. You must read these Terms and Conditions carefully in their
                entirety before creating an account. If you do not agree with
                any provision of these Terms and Conditions, you must not create
                an account or continue to use the Website.
              </p>
              <p>
                1.5. We are entitled to make amendments to these Terms and
                Conditions at any time and without advanced notice. If we make
                such amendments, we may take appropriate steps to bring such
                changes to your attention (such as by email or placing a notice
                on a prominent position on the Website, together with the
                amended terms and conditions) but it shall be your sole
                responsibility to check for any amendments, updates and/or
                modifications. Your continued use of 1stbet services and Website
                after any such amendment to the Terms and Conditions will be
                deemed as your acceptance and agreement to be bound by such
                amendments, updates and/or modifications.
              </p>
              <p>
                1.6. These Terms and Conditions may be published in several
                languages for informational purposes and ease of access by
                players. The English version is the only legal basis of the
              </p>
              <ul className="sub-points">
                <li>
                  ‘Games’ means Casino and other games as may from time to time
                  become available on the Websites
                </li>
                <li>
                  'The Website' means 1stbet through desktop, mobile or other
                  platforms utilized by the Player
                </li>
                <li>
                  1 bitcoin = 1 BTC = 1,000 mBTC (millibitcoins) = 1,000,000
                  µBTC (microbitcoins/bits)
                </li>
                <li>
                  'Cryptocurrency' and 'Crypto' should further be read as
                  'bitcoin, altcoins, and/or (where applicable) other supported
                  cryptocurrencies'relationship between you and us and in the
                  case of any discrepancy with respect to a translation of any
                  kind, the English version of these Terms and Conditions shall
                  prevail.
                </li>
              </ul>
              <h3>2. Binding Declarations</h3>
              <p>
                2.1. By agreeing to be bound by these Terms and Conditions, you
                also agree to be bound by the 1stbet Rules and Privacy Policy
                that are hereby incorporated by reference into these Terms and
                Conditions. In the event of any inconsistency, these Terms and
                Conditions will prevail. You hereby represent and warrant that:
              </p>
              <ul>
                <li>
                  2.1.1. You are over (a) 18 and (b) such other legal age or age
                  of majority as determined by any laws which are applicable to
                  you, whichever age is greater;
                </li>
                <li>
                  2.1.2. You have full capacity to enter into a legally binding
                  agreement with us and you are not restricted by any form of
                  limited legal capacity;
                </li>
                <li>
                  2.1.3. You participate in the Games strictly in your personal
                  and non-professional capacity; and participate for
                  recreational and entertainment purposes only;
                </li>
                <li>
                  2.1.4. You participate in the Games on your own behalf and not
                  on the behalf of any other person;
                </li>
                <li>
                  2.1.5. All information that you provide to us during the term
                  of validity of this agreement is true, complete, correct, and
                  that you shall immediately notify us of any change of such
                  information;
                </li>
                <li>
                  2.1.6. You are solely responsible for reporting and accounting
                  for any taxes applicable to you under relevant laws for any
                  winnings that you receive from us;
                </li>
                <li>
                  2.1.7. You understand that by using our services you take the
                  risk of losing money deposited into your Member Account and
                  accept that you are fully and solely responsible for any such
                  loss;
                </li>
                <li>
                  2.1.8. You are permitted in the jurisdiction in which you are
                  located to use online casino services;
                </li>
                <li>
                  2.1.9. You will not use our services while located in any
                  jurisdiction that prohibits the placing and/or accepting of
                  bets online (incl. denominated in cryptocurrency), and/or
                  playing casino and/or live games including for and/or with
                  crypto;
                </li>
                <li>
                  2.1.10. In relation to deposits and withdrawals of funds into
                  and from your Member Account, you shall only use crypto and/or
                  any Fiat currency that is valid and lawfully belongs to you;
                </li>
                <li>
                  2.1.11. You accept and acknowledge that the value of
                  cryptocurrency can change dramatically depending on the market
                  value;
                </li>
                <li>
                  2.1.12. The computer software, the computer graphics, the
                  Websites and the user interface that we make available to you
                  is owned by 1stbet or its associates and is protected by
                  copyright laws. You may only use the software for your own
                  personal, recreational uses in accordance with all rules,
                  terms and conditions we have established and in accordance
                  with all applicable laws, rules and regulations;
                </li>
                <li>
                  2.1.13. You understand that crypto is not considered a legal
                  currency or tender and as such on the Website they are treated
                  as virtual funds with no intrinsic value.
                </li>
                <li>
                  2.1.14. You affirm that you are not an officer, director,
                  employee, consultant or agent of 1stbet or working for any
                  company related to 1stbet, or a relative or spouse of any of
                  the foregoing;
                </li>
                <li>
                  2.1.15. You are not diagnosed or classified as a compulsive or
                  problem gambler. We are not accountable if such problem
                  gambling arises whilst using our services, but will endeavour
                  to inform of relevant assistance available. We reserve the
                  right to implement cool off periods if we believe such actions
                  will be of benefit.
                </li>
                <li>
                  2.1.16. You are not politically exposed person or a family
                  member of a politically exposed person;
                </li>
                <li>
                  2.1.17. You have only one account with us and agree to not to
                  open any more accounts with us;
                </li>
                <li>
                  2.1.18. You accept and acknowledge that we reserve the right
                  to detect and prevent the use of prohibited techniques,
                  including but not limited to fraudulent transaction detection,
                  automated registration and signup, gameplay and screen capture
                  techniques. These steps may include, but are not limited to,
                  examination of Players device properties, detection of
                  geo-location and IP masking, transactions and blockchain
                  analysis;
                </li>
                <li>
                  2.1.19. You accept our right to terminate and/or change any
                  games or events being offered on the Website, and to refuse
                  and/or limit bets
                </li>
              </ul>
              <h3>3. Your Member Account</h3>
              <p>
                3.1. In order for you to be able to place bets on our websites,
                you must first personally register an account with us (“Member
                Account”).
              </p>
              <p>
                3.2. We do not wish to and shall not accept registration from
                persons resident in jurisdictions that prohibit you from
                participating in online gambling, gaming, and/or games of skill,
                for and/or with cryptocurrencies. By registering an account with
                us you confirm that you're not using any third party software to
                access our sites from jurisdictions that are prohibited, please
                refer to section{" "}
              </p>
              <p>3.3 for the jurisdictions that are prohibited.</p>
              <p>
                3.4. When attempting to open an account or using the Website, it
                is the responsibility of the player to verify whether gambling
                is legal in that particular jurisdiction. If you open or use the
                Website while residing in a Restricted Jurisdiction: your
                account may be closed by us immediately; any winnings and
                rewards will be confiscated and remaining balance returned
                (subject to reasonable charges), and any returns, winnings or
                rewards which you have gained or accrued will be forfeited by
                you and may be reclaimed by us; and you will return to us on
                demand any such funds which have been withdrawn.
              </p>
              <p>
                3.5. You are allowed to have only one Member Account. If you
                attempt to open more than one Member Account, all of your
                accounts may be blocked, suspended or closed and any crypto
                amount credited to your account/s will be frozen.
              </p>
              <p>
                3.6. If you notice that you have more than one registered Member
                Account you must notify us immediately. Failure to do so may
                lead to your Member Account being blocked.{" "}
              </p>
              <p>
                3.7. You will inform us as soon as you become aware of any
                errors with respect to your account or any calculations with
                respect to any bet you have placed. We reserve the right to
                declare null and void any bets that are subject to such an
                error.
              </p>
              <p>
                3.8. If you do not use your account that has deposited funds for
                3 months, you will receive a notice from us. If you do not use
                your deposits after our notice within 1 month, we reserve the
                right to deduct monthly administrative costs from your deposit
                remaining on your account. The administrative cost may be up to
                15% from your deposited funds remaining on your account. After
                180 days we may remove the balance from your account to increase
                security on the funds. If this happens contact us at
                support@1stbet to reopen your account.
              </p>
              <p>
                3.9. You must enter all mandatory information requested into the
                registration form, including a valid email address. If you do
                not enter a valid email address, we will be unable to help you
                recover any “forgotten passwords”. It is your sole
                responsibility to ensure that the information you provide is
                true, complete and correct.
              </p>
              <p>
                3.10. We have the right to carry out “KYC” (Know Your Customer)
                verification procedures and access to your Member Account may be
                blocked or closed if we determine that you have supplied false
                or misleading information. We reserve the right to block / delay
                processing of withdrawal requests until requested copies of
                valid photographic identification, proof of address and/or
                credit/debit card are provided to our satisfaction (KYC).
              </p>
              <p>
                3.13. If you change your password, you will be unable to
                withdraw for 48 hours due to security reasons.
              </p>
              <h3>4. Deposits</h3>
              <p>
                4.1. You may participate in any Game only if you have sufficient
                funds on your Member Account for such participation. We shall
                not give you any credit whatsoever for participation in any
                Game.
              </p>
              <p>
                4.2. The minimum deposit amounts are as follows:
                <ul>
                  <li>0.25 mBTC (0.00025 BTC)</li>
                  <li>100 TRX</li>
                  <li>0.005 ETH </li>
                  <li>10 USDT</li>
                </ul>
                minimum deposit amounts specified are per single transaction.
                Smaller deposits will not be summed. Any deposits below the
                specified minimum deposit amounts are void and will not be
                credited to your user account balance or returned to the source.
                1stbet shall not be held liable if deposits do not meet our
                listed minimum deposit requirements.
              </p>
              <p>
                4.3. To deposit funds into your Member Account, you can transfer
                funds from crypto-wallets and credit cards under your control.
                Deposits can only be made with your own funds.
              </p>
              <p>
                4.4. We reserve the right to use additional procedures and means
                to verify your identity when processing deposits into your
                Member Account.
              </p>
              <p>
                4.5. Note that some payment methods may include an additional
                fee. In this case, the fee will be clearly visible for you in
                the cashier.
              </p>
              <p>
                4.6. Note that your bank or payment service provider may charge
                you additional fees for deposits of currency conversion
                according to their terms and conditions and your user agreement.
              </p>
              <h3>5. Withdrawals</h3>
              <p>
                5.1. All withdrawals shall be processed in accordance with our
                withdrawal policy. Crypto withdrawals will be made to your
                stated cryptocurrency wallet address when making a valid
                withdrawal request. To make a withdrawal, you first need to
                verify your registered email address from your account profile.
                To withdraw any funds which have been deposited, we require
                there to be at least 3 blockchain confirmations of the deposit
                before a withdrawal can be requested.
              </p>
              <p>
                If your withdrawal amount is equal or less than your deposit,
                you must use the same payment option as you used for the
                deposit. The rest can be withdrawn using any payment method.
              </p>
              <p>
                5.2. The minimum withdrawal amounts are as follows:
                <ul>
                  <li>0.5 mBTC (0.0005 BTC)</li>
                  <li>200 TRX</li>
                  <li>0.01 ETH </li>
                  <li>20 USDT</li>
                </ul>
                amounts specified here are per single transaction. All Euro
                withdrawals shall be processed in accordance with our withdrawal
                policy. Euro withdrawals will be made to your personal Euro
                account when making a valid withdrawal request.
              </p>
              <p>
                5.3. If we mistakenly credit your Member Account with winnings
                that do not belong to you, whether due to a technical error in
                the pay-tables, or human error or otherwise, the amount will
                remain our property and will be deducted from your Member
                Account. If you have withdrawn funds that do not belong to you
                prior to us becoming aware of the error, the mistakenly paid
                amount will (without prejudice to other remedies and actions
                that may be available at law) constitute a debt owed by you to
                us. In the event of an incorrect crediting, you are obliged to
                notify us immediately by email.
              </p>
              <p>
                5.4. 1stbet reserves the right to carry out additional KYC
                verification procedures for any withdrawal exceeding 2,000 EUR
                or the same value in cryptocurrencies, Account Holders who wish
                to recover funds held in a closed, locked or excluded account,
                are advised to contact Customer Support.
              </p>
              <p>
                5.5. All transactions shall be checked in order to prevent money
                laundering. If the Member becomes aware of any suspicious
                activity relating to any of the Games of the Website, s/he must
                report this to the Company immediately. 1stbet may suspend,
                block or close a Member Account and withhold funds if requested
                to do so in accordance with the Prevention of Money Laundering
                Act or on any other legal basis requested by any state
                authority. Enhanced due diligence may be done in respect of
                withdrawals of funds not used for wagering.
              </p>
              <p>
                5.6. We reserve the right to apply a wagering requirement of at
                least 5 (five) times the deposit amount if we suspect the player
                is using our service as a mixer. It is strictly forbidden to use
                our service for any other purpose than entertainment.
              </p>
              <p>
                5.7. You acknowledge that the funds in your account are consumed
                instantly when playing and we do not provide return of goods,
                refunds or retrospective cancellation of your account.
              </p>
              <p>
                5.8. If you win €250,000 (two hundred and fifty thousand euros)
                or more, or the equivalent in any currency we provide, we
                reserve the right to pay a maximum of up to €250,000 (two
                hundred and fifty thousand euros) per week until the full amount
                is settled.
              </p>
              <p>
                5.9. You will not earn any interest on outstanding amounts and
                acknowledge that the Company is not a financial institution.
              </p>
              <p>
                5.10. If you are eligible for a reward, for example a login
                reward or a deposit reward of 100% up to a certain amount,
                wagering requirements will apply before you are eligible to make
                any cash-outs of the reward or winnings. The wagering
                requirements, which can vary, will be displayed when receiving
                the reward. If you would like to request a withdrawal before the
                wagering requirements are fulfilled, 1stbet will deduct the
                whole reward amount as well as any winnings before approving the
                withdrawal. 1stbet reserves the right to impose, at our own
                discretion, geographical limitations to individual reward
                schemes. Local wagering requirements may be applied.
                Rewards/free spins at 1stbet can only be received once per
                household/IP (several accounts registered with the same IP
                address). 1stbet ’s wagering requirements do not apply to risk
                free bets.
              </p>
              <p>
                5.11. You must use your reward and/ or reward program within 30
                days from receiving the reward on your Member Account. When the
                reward and/ or reward program has not been used within 30 days
                from receiving it, 1stbet reserves the right to cancel any such
                reward and/ or reward program and may deduct the reward or
                reward-like reward or freespin immediately after the lapse of
                the 30 day period.
              </p>
              <p>
                5.12. 1stbet reserves the right to cancel any reward or reward
                program (including, but not limited to free money rewards,
                deposit rewards, reload rewards and loyalty program) with
                immediate effect if we believe the reward has been set up
                incorrectly or is being abused, and if said reward has been paid
                out then we reserve the right to decline any withdrawal request
                and to deduct such amount from your account. Whether or not a
                reward is deemed to be set up incorrectly or abused shall be
                determined solely at the discretion of 1stbet.
              </p>
              <p>
                5.13. You acknowledge and understand that separate terms and
                conditions exist with respect to promotions, rewards and special
                offers, and are in addition to these terms and conditions. These
                terms and conditions are set forth in the respective content
                page on this website (1stbet), or have been delivered to you
                personally, as the case may be. In the event of a conflict
                between the provisions of such promotions, rewards and special
                offers, and the provisions of these terms and conditions, the
                provisions of such promotions, rewards and special offers will
                prevail.
              </p>
              <p>
                5.14. We reserve the right to insist that Customers bet the full
                amount of their own deposit before they can bet with the free
                money we credit to them.
              </p>
              <p>
                5.15. Certain promotions may be subject to withdrawal and/or
                cancellation and may only be available for specific periods and
                on certain specific terms. You must ensure that the promotion
                you are interested in is still available, that you are eligible,
                and that you understand any terms which apply to it.
              </p>
              <p>
                5.16. Where any term of the offer or promotion is breached or
                there is any evidence of a series of bets placed by a customer
                or group of customers, which due to a deposit reward, enhanced
                payments, free bets, risk free bets or any other promotional
                offer results in guaranteed customer profits irrespective of the
                outcome, whether individually or as part of a group, 1stbet
                reserves the right to reclaim the reward element of such offers
                and in their absolute discretion either settle bets at the
                correct odds, void the free bet reward and risk free bets or
                void any bet funded by the deposit reward. In addition, 1stbet
                reserves the right to levy an administration charge on the
                customer up to the value of the deposit reward, free bet reward,
                risk free bet or additional payment to cover administrative
                costs. We further reserve the right to ask any customer to
                provide sufficient documentation for us to be satisfied in our
                absolute discretion as to the customer's identity prior to us
                crediting any reward, free bet, risk free bet or offer to their
                account.
              </p>
              <p>
                5.17. All 1stbet offers are intended for recreational players
                and 1stbet may in its sole discretion limit the eligibility of
                customers to participate in all or part of any promotion.
              </p>
              <p>
                5.18. Reward rounds and free spins do not qualify for the
                jackpot rewards pursuant to casino software provider rules. Only
                real money rounds qualify for the jackpot rewards.
              </p>
              <p>
                5.19. If we determine, in our sole discretion, that you are
                using the “Double Spend“ methodology, 1stbet shall void all bets
                and winnings. Specifically, if you win, then confirm your
                deposit on the Blockchain and attempt to withdraw, all winnings
                will be confiscated and your account will be closed permanently.
                We shall also exercise this right where similar activities are
                attempted from any connected accounts.
              </p>
              <p>
                5.20. Note that some payment methods may include an additional
                fee. In this case, the fee will be clearly visible for you in
                the cashier.
              </p>
              <p>
                5.21. Note that your bank or payment service provider may charge
                you additional fees for withdrawals of currency conversion
                according to their terms and conditions and your user agreement.
              </p>
              <h3>6. Closing of Member Accounts</h3>
              <p>
                6.1. If you wish to close your member account, you may do so at
                any time, by contacting customer support in written form via
                email. The effective closure of the Account will correspond to
                the termination of the Terms and Conditions. If the reason
                behind the closure of the Account is related to concerns about
                possible gambling addiction, you shall indicate this in writing
                when requesting the closure.
              </p>
              <h3>7. Privacy Policy</h3>
              <p>
                7.1. You hereby acknowledge and accept that if we deem
                necessary, we are able to collect and otherwise use your
                personal data in order to allow you access and use of the
                Websites and in order to allow you to participate in the Games.
              </p>
              <p>
                {" "}
                7.2. We hereby acknowledge that in collecting your personal
                details as stated in the previous provision, we are bound by the
                Data Protection Act. We will protect your personal information
                and respect your privacy in accordance with best business
                practices and applicable laws.
              </p>
              <p>
                {" "}
                7.3. We will use your personal data to allow you to participate
                in the Games and to carry out operations relevant to your
                participation in the Games. We may also use your personal data
                to inform you of changes, new services and promotions that we
                think you may find interesting. If you do not wish to receive
                such direct marketing correspondences, you may opt out of the
                service.
              </p>
              <p>
                {" "}
                7.4. Your personal data will not be disclosed to third parties,
                unless such disclosure is necessary for the processing of your
                requests in relation to your participation in the Games or
                unless it is required by law. As 1stbet's business partners or
                suppliers or service providers may be responsible for certain
                parts of the overall functioning or operation of the Website,
                personal data may be disclosed to them. The employees of 1stbet
                have access to your personal data for the purpose of executing
                their duties and providing you with the best possible assistance
                and service. You hereby consent to such disclosures.
              </p>
              <p>
                {" "}
                7.5. We shall keep all information provided as personal data.
                You have the right to access personal data held by us about you.
                No data shall be destroyed unless required by law, or unless the
                information held is no longer required to be kept for the
                purpose of the relationship.
              </p>
              <p>
                {" "}
                7.6. In order to make your visit to the Websites more
                user-friendly, to keep track of visits to the Websites and to
                improve the service, we collect a small piece of information
                sent from your browser, called a cookie. You can, if you wish,
                turn off the collection of cookies. You must note, however, that
                turning off cookies may severely restrict or completely hinder
                your use of the Websites.
              </p>
              <h3>8. General Betting Rules</h3>
              <p>
                8.1. A bet can only be placed by a registered account holder.
              </p>
              <p>8.2. A bet can only be placed over the internet.</p>
              <p>
                8.3. You can only place a bet if you have sufficient funds in
                your account with 1stbet.
              </p>
              <p>
                8.4. The bet, once concluded, will be governed by the version of
                the Terms and Conditions valid and available on the Website at
                the time of the bet being accepted.
              </p>
              <p>
                8.5. Any payout of a winning bet is credited to your account,
                consisting of the stake multiplied by the odds at which the bet
                was placed.
              </p>
              <p>
                {" "}
                8.6. 1stbet reserves the right to adjust a bet payout credited
                to a 1stbet account if it is determined by 1stbet in its sole
                discretion that such a payout has been credited due to an error.
              </p>
              <p>
                {" "}
                8.7. A bet, which has been placed and accepted, cannot be
                amended, withdrawn or cancelled by you.
              </p>
              <p>
                {" "}
                8.8. The list of all the bets, their status and details are
                available to you on the Website.
              </p>
              <p>
                {" "}
                8.9. When you place a bet you acknowledge that you have read and
                understood in full all of these Terms and Conditions regarding
                the bet as stated on the Website.
              </p>
              <p>
                {" "}
                8.10. 1stbet manages your account, calculates the available
                funds, the pending funds, the betting funds as well as the
                amount of winnings. Unless proven otherwise, these amounts are
                considered as final and are deemed to be accurate.
              </p>
              <p> 8.11. You are fully responsible for the bets placed.</p>
              <p>
                {" "}
                8.12. Winnings will be paid into your account after the final
                result is confirmed.
              </p>
              <h3>9. Miscarried and aborted games</h3>
              <p>
                9.1. The Company is not liable for any downtime, server
                disruptions, lagging, or any technical or political disturbance
                to the game play. Refunds may be given solely at the discretion
                of 1stbet management.
              </p>
              <p>
                9.2. The Company shall accept no liability for any damages or
                losses which are deemed or alleged to have arisen out of or in
                connection with the website or its content; including without
                limitation, delays or interruptions in operation or
                transmission, loss or corruption of data, communication or lines
                failure, any person's misuse of the site or its content or any
                errors or omissions in content.
              </p>
              <p>
                9.3. In the event of a Casino system malfunction all wagers are
                void.
              </p>
              <h3>10. Rewards and Promotions</h3>
              <p>
                10.1. 1stbet reserves the right to cancel any promotion, reward
                or reward program (including, but not limited to deposit
                rewards, reload rewards and loyalty programs) with immediate
                effect if we believe the reward has been set up incorrectly, is
                being abused, has not been used within 30 days from receiving it
                on your account, and if said reward has been paid out, we
                reserve the right to decline any withdrawal request and to
                deduct such amount from your account. Whether or not a reward is
                deemed to be set up incorrectly or abused shall be determined
                solely by 1stbet.
              </p>
              <p>
                10.2. If you use a Deposit reward, no withdrawal of your
                original deposit will be accepted before you have reached the
                requirements stipulated under the terms and conditions of the
                Deposit reward.
              </p>
              <p>
                10.3. Where any term of the offer or promotion is breached or
                there is any evidence of a series of bets placed by a customer
                or group of customers, which due to a deposit reward, enhanced
                payments, free bets, risk free bets or any other promotional
                offer results in guaranteed customer profits irrespective of the
                outcome, whether individually or as part of a group, 1stbet
                reserves the right to reclaim the reward element of such offers
                and in their absolute discretion either settle bets at the
                correct odds, void the free bet reward and risk free bets or
                void any bet funded by the deposit reward. In addition, 1stbet
                reserves the right to levy an administration charge on the
                customer up to the value of the deposit reward, free bet reward,
                risk free bet or additional payment to cover administrative
                costs. We further reserve the right to ask any customer to
                provide sufficient documentation for us to be satisfied in our
                absolute discretion as to the customer's identity prior to us
                crediting any reward, free bet, risk free bet or offer to their
                account.
              </p>
              <p>
                10.4. All 1stbet offers are intended for recreational players
                and 1stbet may in its sole discretion limit the eligibility of
                customers to participate in all or part of any promotion.
              </p>
              <p>
                10.5. 1stbet reserves the right to amend, cancel, reclaim or
                refuse any promotion at its own discretion.
              </p>
              <p>
                10.6. Rewards can only be received once per person/account,
                family, household, address, e-mail address, IP addresses and
                environments where computers are shared (university, fraternity,
                school, public library, workplace, etc.). The Operator reserves
                the right to close your account and confiscate any existing
                funds if evidence of abuse/fraud is found.
              </p>
              <p>
                10.7. You acknowledge and understand that separate terms and
                conditions exist with respect to promotions, rewards and special
                offers, and are in addition to these terms and conditions. These
                terms and conditions are set forth in the respective content
                page on this website, or have been made available to you
                personally, as the case may be. In the event of a conflict
                between the provisions of such promotions, rewards and special
                offers, and the provisions of these terms and conditions, the
                provisions of such promotions, rewards and special offers will
                prevail.
              </p>
              <p>
                10.8. We may insist that you bet a certain amount of your own
                deposit before you can bet with any free/reward funds we credit
                to your account.
              </p>
              <p>
                10.9. You accept that certain promotions may be subject to
                withdrawal restrictions and/or requirements which need to be met
                before funds credited under the promotion can be withdrawn. Such
                terms shall be duly published and made available as part of the
                promotion. If you opt to make a withdrawal before the applicable
                wagering requirements are fulfilled, we will deduct the whole
                reward amount as well as any winnings connected with the use of
                the reward amounts before approving any withdrawal.
              </p>
              <h3>11. Live Chat</h3>
              <p>
                11.1. As part of your use of the Website we may provide you with
                a live chat facility, which is moderated by us and subject to
                controls. We reserve the right to review the chat and to keep a
                record of all statements made on the facility. Your use of the
                chat facility should be for recreational and socializing
                purposes. 11.2. We have the right to remove the chat room
                functionality or immediately terminate your Member Account and
                refund your account balance if you:
                <ul>
                  <li>
                    (a) make any statements that are sexually explicit or
                    grossly offensive, including expressions of bigotry, racism,
                    hatred or profanity;
                  </li>
                  <li>
                    (b) make statements that are abusive, defamatory or
                    harassing or insulting;
                  </li>
                  <li>
                    (c) use the chat facility to advertise, promote or otherwise
                    relate to any other online entities;
                  </li>
                  <li>
                    (d) make statements about 1stbet, or any other Internet
                    site(s) connected to the Website that are untrue and/or
                    malicious and/or damaging to 1stbet;
                  </li>
                  <li>
                    (e) use the chat facility to collude, engage in unlawful
                    conduct or encourage conduct we deem seriously
                    inappropriate. Any suspicious chats will be reported to the
                    competent authority.
                  </li>
                </ul>
              </p>
              <p>
                11.3. Live Chat is used as a form of communication between us
                and you and should not be copied or shared with any forums or
                third parties.
              </p>
              <h3>12. Limitation of Liability</h3>
              <p>
                12.1. You enter the Website and participate in the Games at your
                own risk. The Websites and the Games are provided without any
                warranty whatsoever, whether expressed or implied.
              </p>
              <p>
                {" "}
                12.2. Without prejudice to the generality of the preceding
                provision, we, our directors, employees, partners, service
                providers
                <ul>
                  <li>
                    12.2.1. Do not warrant that the software, Games and the
                    Websites are fit for their purpose;
                  </li>
                  <li>
                    12.2.2. Do not warrant that the software, Games and the
                    Websites are free from errors;
                  </li>
                  <li>
                    12.2.3. Do not warrant that the software, Games and the
                    Websites will be accessible without interruptions
                  </li>
                  <li>
                    12.2.4. Shall not be liable for any loss, costs, expenses or
                    damages, whether direct, indirect, special, consequential,
                    incidental or otherwise, arising in relation to your use of
                    the Websites or your participation in the Games.
                  </li>
                </ul>
              </p>
              <p>
                12.3. You understand and acknowledge that, if there is a
                malfunction in a Game or its interoperability, any bets made
                during such a malfunction shall be void. Funds obtained from a
                malfunctioning Game shall be considered void, as well as any
                subsequent game rounds with said funds, regardless of what Games
                are played using such funds.
              </p>
              <p>
                12.4. You hereby agree to fully indemnify and hold harmless us,
                our directors, employees, partners, and service providers for
                any cost, expense, loss, damages, claims and liabilities however
                caused that may arise in relation to your use of the Website or
                participation in the Games.{" "}
              </p>
              <p>
                12.5. To the extent permitted by law, our maximum liability
                arising out of or in connection with your use of the Websites,
                regardless of the cause of actions (whether in contract, tort,
                breach of warranty or otherwise), will not exceed €100.
              </p>
              <h3>13. Breaches, Penalties and Termination</h3>
              <p>
                13.1. If you breach any provision of these Terms and Conditions
                or we have a reasonable ground to suspect that you have breached
                them, we reserve the right to not open, to suspend, or to close
                your Member Account, or withhold payment of your winnings and
                apply such funds to any damages due by you.
              </p>
              <h3>14. Severability</h3>
              <p>
                14.1. If any provision of these Terms and Conditions is held to
                be illegal or unenforceable, such provision shall be severed
                from these Terms and Conditions and all other provisions shall
                remain in force unaffected by such severance.
              </p>
              <h3>15. Assignment</h3>
              <p>
                15.1. No assignment of Your obligations hereunder is allowed.
                You may not assign your obligations under this Agreement, as
                well as any rights or obligations hereunder to any other person
                or entity.
              </p>
              <h3>16. Entire Agreement</h3>
              <p>
                16.1. These Terms and Conditions constitute the entire agreement
                between you and us with respect to the Websites and, save in the
                case of fraud, supersede all prior or contemporaneous
                communications and proposals, whether electronic, oral or
                written, between you and us with respect to the Websites.
              </p>
              <h3>17. Complaints</h3>
              <p>
                17.1. If you have a complaint to make regarding our services,
                you may contact our customer support via Live Chat or by email
                the address provided on https://www.1stbet. 1stbet will endeavor
                to resolve the matter promptly
              </p>
            </div>
          </div>
        </main>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default TermsConditionsss;
