import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

const FairPlay = () => {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <main class="main page-content">
        <div class="container">
          <h3 class="page-heading">Fair Play</h3>
          <p>
            1stbet treats all Players equally and respectfully. 1stbet Provide
            Fair Odds and Fair winnings. Fair competition, respect, friendship,
            team spirit, equality, sport without doping, respect for written and
            unwritten rules such as integrity, solidarity, tolerance, care,
            excellence and joy, are the building blocks of fair play that can be
            experienced and learnt both on and off the field.
          </p>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default FairPlay;
