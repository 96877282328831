import { ACCOUNT_STATEMENT, ALL_GATEWAYS, SELECTED_GATEWAY } from "./types";

const initialState = {
  accountStatement: null,
};

export default (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case ACCOUNT_STATEMENT:
      let data = payload.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return {
        ...state,
        accountStatement: data,
      };
    case ALL_GATEWAYS:
      sessionStorage.setItem("gatewayList", JSON.stringify(payload));
      return {
        ...state,
        gatewaysList: payload,
      };
    case SELECTED_GATEWAY:
      sessionStorage.setItem("selectedGateway", JSON.stringify(payload));
      return {
        ...state,
        selectedGateway: payload,
      };
    default:
      return state;
  }
};
