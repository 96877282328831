import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import autoRevolution from "../../../../assets/images/autoR-evolution.png";
import TurkeLightRevolution from "../../../../assets/images/turke-light-roulet-evolution.png";
import SuperSevolution from "../../../../assets/images/SSBo-evolution.png";
import SpeedBaccaratevolution from "../../../../assets/images/speed-baccarat-Evolution.png";
import Megaball from "../../../../assets/images/megaball-evolution.png";
import LightRevolition from "../../../../assets/images/light-rulet.png";
import lightDiceevolution from "../../../../assets/images/light-dice.png";
import GonzoTresure from "../../../../assets/images/gonzoT-Evolution.png";
import FreeBetevolution from "../../../../assets/images/FreeBet-Black-evolution.png";
import Footballevolution from "../../../../assets/images/football-evolution.png";
import Deadevolution from "../../../../assets/images/DOA-Evolution.png";
import DreamCatch from "../../../../assets/images/DC-Evoluton.png";
import CrazyTEvolution from "../../../../assets/images/CrazyTEvolution.png";
import CashCrash from "../../../../assets/images/cashorcrash.png";
import Bacbo from "../../../../assets/images/bacboEvolution.png";

const EvolutionPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="conatiner">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="sectionTitle">Evolution</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>
          <div className="casino-images-section">
            <a href="/casino/evolution/AutoRoulette1" className="casino-images">
              <img src={autoRevolution} />
              <p>Auto Roulette</p>
            </a>
            <a
              href="/casino/evolution/lightTurRouletteEvolu"
              className="casino-images"
            >
              <img src={TurkeLightRevolution} />
              <p>Lightning Turkce Rulet</p>
            </a>
            <a
              href="/casino/evolution/supersicboEvolu"
              className="casino-images"
            >
              <img src={SuperSevolution} />
              <p>Super Sic Bo </p>
            </a>
            <a
              href="/casino/evolution/SpeedBaccaratLiveEvolu"
              className="casino-images"
            >
              <img src={SpeedBaccaratevolution} />
              <p>Speed Baccarat A Live </p>
            </a>
            <a href="/casino/evolution/magaballEvolu" className="casino-images">
              <img src={Megaball} />
              <p>Mega Ball </p>
            </a>
            <a
              href="/casino/evolution/lightrouletteEvolu"
              className="casino-images"
            >
              <img src={LightRevolition} />
              <p>Lightning Roulette</p>
            </a>
            <a
              href="/casino/evolution/lightdiceEvolu"
              className="casino-images"
            >
              <img src={lightDiceevolution} />
              <p>Lighting Dice</p>
            </a>
            <a
              href="/casino/evolution/gonzohuntEvolu"
              className="casino-images"
            >
              <img src={GonzoTresure} />
              <p>Gonzo's Treasure Hunt</p>
            </a>
            <a href="/casino/evolution/freebetEvolu" className="casino-images">
              <img src={FreeBetevolution} />
              <p>Free Bet Blackjack</p>
            </a>
            <a
              href="/casino/evolution/footballstudioEvolu"
              className="casino-images"
            >
              <img src={Footballevolution} />
              <p>Football Studio</p>
            </a>
            {/* <a href="/casino/evolution/dealaliveEvolu" className="casino-images">
              <img src={Deadevolution} />
              <p> Deal or Alive Saloon</p>
            </a> */}
            <a
              href="/casino/evolution/dreamcatchEvolu"
              className="casino-images"
            >
              <img src={DreamCatch} />
              <p>Dream Catcher</p>
            </a>
            <a
              href="/casino/evolution/crazytimeEvolu"
              className="casino-images"
            >
              <img src={CrazyTEvolution} />
              <p>Crazy Time</p>
            </a>
            <a
              href="/casino/evolution/cashcrashEvolu"
              className="casino-images"
            >
              <img src={CashCrash} />
              <p>Cash or Crash</p>
            </a>
            {/* <a href="/casino/evolution/cashcrashEvolu" className="casino-images">
              <img src={Bacbo} />
              <p>Bac Bo</p>
            </a> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default EvolutionPage;
