export const EvolutionGames = {
  aviator: {
    href: "/casino/spribe/aviator",
    code: "aviator",
    casino: "spribe",
    provider: "aviator",
    homeUrl: "",
    cover: { src: "./images/spribe/Aviator.png", alt: "" },
  },
  mines: {
    href: "/casino/spribe/mines",
    code: "mines",
    casino: "spribe",
    provider: "mines",
    homeUrl: "",
    cover: { src: "./images/spribe/mines.png", alt: "" },
  },
  goal: {
    href: "/casino/spribe/goal",
    code: "goal",
    casino: "spribe",
    provider: "goal",
    homeUrl: "",
    cover: { src: "./images/spribe/goal.png", alt: "" },
  },
  dice: {
    href: "/casino/spribe/dice",
    code: "dice",
    casino: "spribe",
    provider: "dice",
    homeUrl: "",
    cover: { src: "./images/spribe/Dice.png", alt: "" },
  },
  plinko: {
    href: "/casino/spribe/plinko",
    code: "plinko",
    casino: "spribe",
    provider: "plinko",
    homeUrl: "",
    cover: { src: "./images/spribe/plinko.png", alt: "" },
  },
  miniroulette: {
    href: "/casino/spribe/miniroulette",
    code: "mini-roulette",
    casino: "spribe",
    provider: "mini-roulette",
    homeUrl: "",
    cover: { src: "./images/spribe/mini-roulette.png", alt: "" },
  },
  hilo: {
    href: "/casino/spribe/hilo",
    code: "hi-lo",
    casino: "spribe",
    provider: "hi-lo",
    homeUrl: "",
    cover: { src: "./images/spribe/Hilo.png", alt: "" },
  },

  // AndarBaharSupernowa: {
  //   href: "/casino/evolution/AndarBaharSupernowa",
  //   code: "ABC",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/spribe/supernowa/andarbaharsupernowa.jpg", alt: "" },
  // },
  // AKWSupernowa: {
  //   href: "/casino/evolution/AKWSupernowa",
  //   code: "ARW",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/supernowa/ARWsupernowa.jpg", alt: "" },
  // },
  // DragonTigerSupernowa: {
  //   href: "/casino/evolution/DragonTigerSupernowa",
  //   code: "DT7M",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/supernowa/dragontigersupernowa.jpg", alt: "" },
  // },
  // PokerSupernowa: {
  //   href: "/casino/evolution/PokerSupernowa",
  //   code: "PK",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/supernowa/pokersepernowa.jpg", alt: "" },
  // },
  // TeenPatti2020Supernowa: {
  //   href: "/casino/evolution/TeenPatti2020Supernowa",
  //   code: "TP20",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/supernowa/teenpatti2020supernowa.jpg", alt: "" },
  // },
  // TeenPattiSupernowa: {
  //   href: "/casino/evolution/TeenPattiSupernowa",
  //   code: "TP",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/supernowa/teenpattisupernowa.jpg", alt: "" },
  // },
  // WorliMatkaSupernowa: {
  //   href: "/casino/evolution/WorliMatkaSupernowa",
  //   code: "WM",
  //   casino: "wco",
  //   provider: "SN",
  //   homeUrl: "",
  //   cover: { src: "./images/supernowa/worlimatkasupernowa.jpg", alt: "" },
  // },

  AutoRoulette: {
    href: "/casino/evolution/AutoRoulette",
    code: "1000123",
    casino: "evolution",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  AutoRoulette1: {
    href: "/casino/evolution/AutoRoulette1",
    code: "1000123",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  lightTurRouletteEvolu: {
    href: "/casino/evolution/lightTurRouletteEvolu",
    code: "1000316",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratLiveEvolu: {
    href: "/casino/evolution/SpeedBaccaratLiveEvolu",
    code: "1000021",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  magaballEvolu: {
    href: "/casino/evolution/magaballEvolu",
    code: "1000141",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  lightrouletteEvolu: {
    href: "/casino/evolution/lightrouletteEvolu",
    code: "1000092",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  lightdiceEvolu: {
    href: "/casino/evolution/lightdiceEvolu",
    code: "1000076",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  gonzohuntEvolu: {
    href: "/casino/evolution/gonzohuntEvolu",
    code: "1100022",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  freebetEvolu: {
    href: "/casino/evolution/freebetEvolu",
    code: "1000066",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  footballstudioEvolu: {
    href: "/casino/evolution/footballstudioEvolu",
    code: "1000112",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  dreamcatchEvolu: {
    href: "/casino/evolution/dreamcatchEvolu",
    code: "1000077",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  crazytimeEvolu: {
    href: "/casino/evolution/crazytimeEvolu",
    code: "1000148",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  cashcrashEvolu: {
    href: "/casino/evolution/cashcrashEvolu",
    code: "1000352",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  supersicboEvolu: {
    href: "/casino/evolution/supersicboEvolu",
    code: "1000110",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
};
