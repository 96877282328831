import React, { useState } from "react";
import TronCode from "../../../../assets/images/tron-qr-code.jpeg";
import { AiTwotoneCopy } from "react-icons/ai";
import TronPay from "../../../../assets/images/tron.png";
import BackIcon from "../../../../assets/svg/BackIcon";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { useNavigate } from "react-router-dom";
import { createWithDrawRequest } from "../../../../redux/user/actions";
import { APP_CONST } from "../../../../config/const";
import { useSelector } from "react-redux";
import { Toaster } from "../../../../utils/toaster";
import { CopyToClipboard } from "react-copy-to-clipboard";
const TronQRCodeWithdraw = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const validateData = (data) => {
    const dataToVerify = data ? data : formData;
    const { accountNo, amount } = dataToVerify;
    let errors = {};
    if (accountNo == "" || !accountNo) {
      errors.accountNo = "Please enter walletNo";
    }
    if (!amount || amount == 0) {
      errors.amount = "Please enter amount";
    }

    setError(errors);
    return errors;
  };
  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    let data = { ...formData, [name]: value };
    validateData(data);
    setFormData(data);
  };

  const handleSubmit = () => {
    if (Object.keys(validateData()).length === 0) {
      setLoading(true);
      createWithDrawRequest({
        ...formData,
        mode: APP_CONST.GATEWAYS.M_BINANCE_TRON,
        mobileNo: user?.mobileno,
      })
        .then((res) => {
          setLoading(false);
          if (res.data.error === false) {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Request Success");
          }
        })
        .catch((err) => {
          setLoading(false);
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    }
  };
  return (
    <div>
      <div>
        <HeaderAfterLogin />
        <main className="main tron">
          <div className=" container">
            <div className="d-flex align-items-center justify-content-between">
              <h2 class="sectionTitle">
                <img src={TronPay} /> Tether on Tron{" "}
              </h2>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>

            <div className="tron-qr-section">
              <div className="tron-form">
                {" "}
                <h2 class="sectionTitle">Request a Withdraw</h2>
                <div className="deposit-form mt-2">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Control
                        type="number"
                        placeholder="Amount*"
                        name="amount"
                        required
                        onChange={handleChange}
                        isInvalid={error?.amount}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error?.amount}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control
                        type="text"
                        placeholder=" Your wallet address*"
                        name="accountNo"
                        onChange={handleChange}
                        isInvalid={error?.accountNo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error?.accountNo}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                  <div className="payment-button">
                    <Button
                      variant="primary"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? "Updating.." : "Make Payment"}
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Return
                    </Button>
                  </div>
                </div>
              </div>
              <div className="tron-qr-code d-none">
                <div className="qr-code-img text-center">
                  <img src={TronCode} />
                </div>
                <div className="tron-qr-content">
                  {/* <p>USDT (TRC 20) = 102.9847602 USD</p> */}
                  <p className="copy-name">
                    Copy the address or scan the QR code:{" "}
                    <span>
                      <b>TLcThfJSy1PqKjd7fbuPyJ3cRHa1L4rMoZ</b>
                    </span>
                    <CopyToClipboard text="TLcThfJSy1PqKjd7fbuPyJ3cRHa1L4rMoZ">
                      <AiTwotoneCopy style={{ cursor: "pointer" }} />
                    </CopyToClipboard>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TronQRCodeWithdraw;
