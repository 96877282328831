import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { AiTwotoneCopy } from "react-icons/ai";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";

import { APP_CONST } from "../../../../config/const";
import { createDepositRequestHandler } from "../../../../redux/user/actions";
import { Toaster } from "../../../../utils/toaster";
import { useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const Nagad = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setAmount(location.state.amount);
  }, [location]);
  const initialForm = {
    name: "",
    walletNo: "",
    txnId: "",
  };

  const [formData, setFormData] = useState(initialForm);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    let data = { ...formData, [e.target.name]: e.target.value };
    validator(data);
    setFormData(data);
  };

  const validator = (payload) => {
    setFormErrors({});
    let errors = {};

    const dataToValidate = payload ? payload : formData;
    const { txnId } = dataToValidate;
    if (!txnId || txnId == "") {
      errors.txnId = "TransactionId is required";
    } else if (txnId.length !== 8) {
      errors.txnId = "TransactionId must be of 8 digits";
    }
    setFormErrors(errors);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = validator(formData);
    if (Object.keys(formErrors).length === 0) {
      if (amount >= 0.91 && amount <= 25000) {
        setLoading(true);
        await createDepositRequestHandler({
          ...formData,
          gateway: APP_CONST.GATEWAYS.M_NAGAD,
          amount,
        })
          .then((res) => {
            setLoading(false);

            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Request success");
            navigate("/cashier");
          })
          .catch((err) => {
            setLoading(false);
            Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
          });
        setFormData(initialForm);
        return;
      } else {
        alert("Amount must be greater than 300 and less than 25000");
      }
    }
  };
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className=" container">
          <div className="d-flex align-items-center justify-content-between">
            <h2 class="sectionTitle">Account Deposit </h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-12">
              <a href="javascript:void(0)" className="copy-name">
                <h5>Bank Name</h5>
                <p>
                  Nagad
                  <CopyToClipboard
                    text="Nagad"
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text copied");
                    }}
                  >
                    <AiTwotoneCopy style={{ cursor: "pointer" }} />
                  </CopyToClipboard>
                </p>
              </a>
            </div>
            <div className="col-md-6 col-12">
              <a href="javascript:void(0)" className="copy-name">
                <h5>Nagad Wallet Number</h5>
                <p>
                  01978836205
                  <CopyToClipboard
                    text="01978836205"
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text copied");
                    }}
                  >
                    <AiTwotoneCopy style={{ cursor: "pointer" }} />
                  </CopyToClipboard>
                  <p>(PLEASE DO CASH OUT ONLY)</p>
                </p>
              </a>
            </div>
          </div>

          <h2 class="sectionTitle">Request a Deposit</h2>
          <div className="deposit-form mt-2">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control
                  type="number"
                  placeholder="Amount*"
                  name="amount"
                  value={amount}
                  required
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  // onChange={handleChange}
                  isInvalid={formErrors?.amount}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors?.amount}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="">
                <Form.Control
                  type="number"
                  placeholder="Your Nagad Wallet Number*"
                  name="walletNo"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="">
                <Form.Control
                  type="text"
                  placeholder="Your Name*"
                  name="name"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="">
                <Form.Control
                  type="text"
                  placeholder="TRX ID (For ex.:71MJEAP0)"
                  name="txnId"
                  required
                  onChange={handleChange}
                  isInvalid={formErrors?.txnId}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors?.txnId}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
            <div className="payment-button">
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Updating..." : "Make Payment"}
              </Button>
              <Button variant="danger">Return</Button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
